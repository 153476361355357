<template>
	<v-card
		class="d-flex align-center"
		color="grey lighten-4"
		flat
		height="100%"
		rounded="0"
	>
		<v-container>
			<v-card
				class="mx-auto pa-0 pa-md-16 rounded-lg"
				max-width="700"
				width="100%"
			>
				<v-container>
					<v-row>
						<v-col class="d-flex justify-center pt-5" cols="12">
							<v-img
								class="mb-6"
								max-height="160"
								max-width="270"
								src="@/assets/logo__slogan--primary.svg"
							/>
						</v-col>

						<v-col
							class="d-flex flex-wrap justify-center"
							cols="12"
							style="z-index: 1"
						>
							<v-btn
								block
								class="font-weight-bold mb-3 rounded white--text"
								color="#496699"
								elevation="0"
								x-large
							>
								Sign in with Facebook
							</v-btn>

							<v-btn
								block
								class="font-weight-bold rounded white--text"
								color="#0077B5"
								elevation="0"
								x-large
							>
								Sign in with LinkedIn
							</v-btn>
						</v-col>

						<v-col class="align-center d-flex justify-center" cols="12">
							<span
								style="
									background: white;
									padding: 0 1rem 0.25rem;
									position: absolute;
								"
							>
								or
							</span>

							<v-divider />
						</v-col>

						<v-col class="d-flex justify-center" cols="12">
							<v-btn
								@click="showScreen('signIn')"
								block
								class="font-weight-bold rounded"
								color="cyan"
								dark
								elevation="0"
								x-large
							>
								Sign in with your email
							</v-btn>
						</v-col>

						<v-col
							class="d-flex flex-wrap justify-center text-body-1"
							cols="12"
						>
							Don't have an account?

							<router-link :to="'/register'">
								<span
									class="col-12 col-sm-auto cyan--text ml-sm-2 pa-0 text-center"
									role="button"
								>
									Sign up with your email
								</span>
							</router-link>
						</v-col>

						<v-col cols="12">
							<v-divider />
						</v-col>

						<v-col class="d-flex justify-center" cols="12">
							<p class="text-body-2 text-center">
								By using the app, you agree to the
								<router-link :to="'/terms'">
									<span class="blue--text text--darken-2" role="button">
										Terms and Conditions
									</span>
								</router-link>

								of use
							</p>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-container>
	</v-card>
</template>

<script>
export default {
	data: () => ({}),
	methods: {
		showScreen(screen) {
			this.$router.push(screen);
		},
	},
};
</script>
