<template>
    <v-form ref="form" class="col-12 overflow-hidden pa-0" lazy-validation>
        <v-row>
            <v-col cols="12">
                <v-card
                    class="px-0 px-md-3 py-3"
                    color="cyan lighten-5"
                    flat
                    rounded="0"
                >
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-card
                                    class="pt-3 px-1 pb-12 rounded-lg"
                                    color="cyan accent-4"
                                    flat
                                >
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div
                                                    class="font-weight-bold text-h6 white--text"
                                                >
                                                    Find your flight
                                                </div>
                                            </v-col>

                                            <v-col
                                                class="d-flex justify-md-end"
                                                cols="12"
                                                md="6"
                                            >
                                                <v-radio-group
                                                    v-model="toggle"
                                                    @change="
                                                        changedSearchType()
                                                    "
                                                    class="ma-0"
                                                    dark
                                                    dense
                                                    row
                                                >
                                                    <v-radio
                                                        color="grey darken-3"
                                                        value="roundTrip"
                                                    >
                                                        <template v-slot:label>
                                                            <strong
                                                                class="grey--text text--lighten-5"
                                                            >
                                                                Round Trip
                                                            </strong>
                                                        </template>
                                                    </v-radio>

                                                    <v-radio
                                                        class="ml-3"
                                                        color="grey darken-3"
                                                        value="oneWay"
                                                    >
                                                        <template v-slot:label>
                                                            <strong
                                                                class="grey--text text--lighten-5"
                                                            >
                                                                One Way
                                                            </strong>
                                                        </template>
                                                    </v-radio>
                                                </v-radio-group>
                                            </v-col>

                                            <v-col
                                                class="d-flex pb-1"
                                                cols="12"
                                                md="8"
                                            >
                                                <v-card
                                                    color="white"
                                                    flat
                                                    rounded
                                                    width="100%"
                                                >
                                                    <v-row>
                                                        <v-col
                                                            class="pb-0 pb-md-3"
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <AirportSearch
                                                                :filled="true"
                                                                :value="
                                                                    getSearchFrom
                                                                "
                                                                color="white"
                                                                text="From"
                                                            />
                                                        </v-col>

                                                        <v-divider
                                                            class="d-md-none mx-3 my-0"
                                                        />

                                                        <v-divider
                                                            class="d-none d-md-flex my-6"
                                                            vertical
                                                        />

                                                        <v-col
                                                            class="pt-0 pt-md-3"
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <AirportSearch
                                                                :filled="true"
                                                                :value="
                                                                    getSearchTo
                                                                "
                                                                color="white"
                                                                text="To"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-col>

                                            <v-col
                                                class="d-flex pb-1"
                                                cols="12"
                                                md="4"
                                            >
                                                <v-card
                                                    color="white"
                                                    flat
                                                    rounded
                                                    width="100%"
                                                >
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <Passengers />
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-col>

                                            <v-col
                                                class="d-flex pb-1"
                                                cols="12"
                                                md="8"
                                            >
                                                <v-card
                                                    color="white"
                                                    flat
                                                    rounded
                                                    width="100%"
                                                >
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <FlightDates />
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-col>

                                            <v-col
                                                class="d-flex pb-1"
                                                cols="12"
                                                md="4"
                                            >
                                                <v-btn
                                                    @click="saveForm()"
                                                    block
                                                    class="font-weight-bold px-12 rounded"
                                                    color="deep-purple darken-4"
                                                    dark
                                                    elevation="0"
                                                    height="64"
                                                    x-large
                                                >
                                                    Search
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AirportSearch from "../../components/search/AirportSearch";
import FlightDates from "../../components/search/FlightDates";
import Passengers from "../../components/search/Passengers";

export default {
    components: {
        AirportSearch,
        FlightDates,
        Passengers,
    },
    data: () => ({
        toggle: "roundTrip",
        dealsToday: 4,
        fakeImage: require("@/assets/deals__nature--mendoza.jpg"),
        categories: ["All", "Airplane", "Helicopter"],
        adultsList: ["0", "1", "2", "3", "4", "5", "6"],
        kidsList: ["0", "1", "2", "3", "4", "5", "6"],
        modalDate1: false,
        adults: null,
        kids: null,
        rules: {
            from: {
                minLenght: 1,
                pass: true,
            },
            to: {
                minLenght: 1,
                pass: true,
            },
        },
        requiredCheck: [(v) => !!v || "Field is required"],
        error: false,
    }),
    methods: {
        ...mapActions([
            "toDateAction",
            "fromDateAction",
            "typeSearchAction",
            "actionFromType",
            "actionToType",
            "flightToAction",
        ]),
        saveForm() {
            if (!this.$refs.form.validate() && this.validate()) {
            } else {
                this.typeSearchAction(this.toggle);
                this.flightToAction({});
                this.actionFromType(null);
                this.actionToType(null);
                this.$router.push("/search/depart");
            }
        },
        changedSearchType() {
            this.typeSearchAction(this.toggle);
        },
        validate() {
            this.rules.from.value = this.getFlightFrom;
            this.rules.to.value = this.getFlightTo;

            for (const prop in this.rules) {
                let elem = this.rules[prop];
                if (elem.value) {
                    if (elem.value.length > elem.minLenght) {
                        elem.pass = true;
                    } else {
                        elem.pass = false;
                        this.error = true;
                    }
                } else {
                    elem.pass = false;
                    this.error = true;
                }
            }

            return this.error;
        },
        valid(element) {
            return !this.rules[element].pass;
        },
        formatDateInput(Dates) {
            let format = "ddd, DD MMM, YYYY HH:mm";
            return (
                Dates.start.format(format) + " - " + Dates.end.format(format)
            );
        },
    },
    computed: {
        ...mapGetters([
            "getOffers",
            "getFlights",
            "getSearchResultSeats",
            "getSearchResultPlane",
            "getFlightFrom",
            "getFlightTo",
            "getSearchFrom",
            "getSearchTo",
            "getFromDate",
            "getToDate",
            "getTypeSearch",
        ]),
    },

    created() {
        this.toggle = this.getTypeSearch;
    },
};
</script>

<style lang="scss">
.v-text-field__details {
    display: none !important;
}
</style>
