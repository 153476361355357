<template>
    <v-app>
        <v-main class="grey lighten-4 pt-md-0">
            <Header v-if="isLoggedIn" class="d-none d-md-block" />

            <AppBar v-if="isLoggedIn" class="d-block d-md-none" />

            <router-view />

            <AppBarBottom v-if="isLoggedIn" class="d-md-none" />

            <Footer v-if="isLoggedIn" class="d-none d-md-block" />
        </v-main>
    </v-app>
</template>

<style lang="scss">
@import "./scss/main.scss";
</style>

<script>
import Header from "@/layouts/Header";
import AppBar from "@/layouts/AppBar";
import AppBarBottom from "@/layouts/AppBarBottom";
import Footer from "@/layouts/Footer";
import { mapGetters } from "vuex";

export default {
    components: {
        Header,
        AppBar,
        AppBarBottom,
        Footer,
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
    },
    updated() {
        this.$refs.main?.scrollTo(0, 0);
    },
};
</script>