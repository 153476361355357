<template>
	<v-card
		class="d-flex align-center"
		color="grey lighten-4"
		flat
		height="100%"
		rounded="0"
	>
		<v-container>
			<v-card
				class="mx-auto px-0 px-md-16 pb-16 rounded-lg"
				max-width="700"
				width="100%"
			>
				<v-container>
					<v-row>
						<v-col class="ml-md-n12 px-0 py-6" cols="12">
							<v-btn @click="$router.go(-1)" class="mr-auto" color="cyan" icon>
								<v-icon>mdi-arrow-left</v-icon>
							</v-btn>
						</v-col>

						<v-col class="d-flex justify-center pt-0" cols="12">
							<v-img
								class="mb-6"
								max-height="160"
								max-width="270"
								src="@/assets/logo__slogan--primary.svg"
							/>
						</v-col>

						<v-form class="col-12 pa-0">
							<v-container class="px-3">
								<v-alert v-model="alertError" type="error">
									{{ alertMessage }}
								</v-alert>
								<v-alert v-model="alertSuccess" type="success" dismissible>
									Account create correctly.
									<router-link :to="'/register'">
										<span
											class="col-12 col-sm-auto cyan--text ml-sm-2 pa-0 text-center"
											role="button"
										>
											Go to login
										</span>
									</router-link>
								</v-alert>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-row>
										<v-col class="d-flex pb-1" cols="12">
											<v-text-field
												v-model="firstName"
												background-color="grey lighten-4"
												class="rounded"
												color="cyan"
												filled
												label="Name"
												placeholder="Jane"
												rounded
												:rules="required3"
											/>
										</v-col>

										<v-col class="d-flex pt-0 pb-1" cols="12">
											<v-text-field
												v-model="lastName"
												background-color="grey lighten-4"
												class="rounded"
												color="cyan"
												filled
												label="Last Name"
												placeholder="Doe"
												rounded
												:rules="required3"
											/>
										</v-col>

										<v-col class="d-flex pt-0 pb-1" cols="12">
											<v-text-field
												v-model="email"
												background-color="grey lighten-4"
												class="rounded"
												filled
												label="Email"
												placeholder="name@jetsbooking.com"
												rounded
												type="email"
												:rules="emailRules"
											/>
										</v-col>

										<v-col class="d-flex pt-0 pb-1" cols="12">
											<v-text-field
												v-model="confirmEmail"
												background-color="grey lighten-4"
												class="rounded"
												filled
												label="Confirm Email"
												placeholder="name@jetsbooking.com"
												rounded
												type="email"
												:rules="emailRules"
											/>
										</v-col>

										<v-col class="d-flex pt-0 pb-1" cols="12">
											<v-text-field
												v-model="password"
												background-color="grey lighten-4"
												class="rounded"
												filled
												label="Password"
												placeholder="**********"
												type="password"
												rounded
												:rules="requiredPassword"
											/>
										</v-col>

										<v-col class="d-flex pt-0 pb-1" cols="12">
											<v-text-field
												v-model="confirmPassword"
												background-color="grey lighten-4"
												class="rounded"
												filled
												label="Confirm Password"
												placeholder="**********"
												type="password"
												rounded
												:rules="requiredPassword"
											/>
										</v-col>

										<v-col class="d-flex justify-center" cols="12">
											<v-btn
												:disabled="!valid"
												@click="createUser()"
												block
												class="font-weight-bold rounded"
												color="cyan"
												dark
												elevation="0"
												x-large
											>
												Create Account
											</v-btn>
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-form>

						<v-col
							class="d-flex flex-wrap justify-center text-body-1"
							cols="12"
						>
							Already have a JetsBooking account?

							<span
								@click="showScreen('/signIn')"
								class="col-12 col-sm-auto font-weight-bold cyan--text ml-sm-2 pa-0 text-center"
								role="button"
							>
								Sign in
							</span>
						</v-col>

						<v-col cols="12">
							<v-divider />
						</v-col>

						<v-col class="d-flex justify-center" cols="12">
							<p class="text-body-2 text-center">
								By continuing, you are accepting the

								<router-link
									:to="'/policies'"
									@click="$emit('showDialog', false)"
								>
									<span class="blue--text text--darken-2" role="button">
										Privacy Policies
									</span>
								</router-link>
							</p>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-container>
	</v-card>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	data: () => ({
		valid: true,
		firstName: '',
		lastName: '',
		email: '',
		confirmEmail: '',
		password: '',
		confirmPassword: '',
		message: '',
		required3: [
			(v) => !!v || 'Field is required',
			(v) => (v && v.length >= 3) || 'Field must be less than 3 characters',
		],
		requiredPassword: [
			(v) => !!v || 'Field is required',
			(v) => (v && v.length >= 5) || 'Field must be less than 5 characters',
		],
		emailRules: [
			(v) => !!v || 'E-mail is required',
			(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		alertError: false,
		alertSuccess: false,
		alertMessage: '',
	}),
	methods: {
		...mapActions(['createUserAction']),
		showScreen(screen) {
			this.$router.push(screen);
		},
		checkPassword() {
			return this.password === this.confirmPassword;
		},
		checkEmail() {
			return this.email === this.confirmEmail;
		},
		formIsValid() {
			if (!this.checkPassword()) {
				this.showErrorAlert('Passwords do not match');
				return false;
			}

			if (!this.checkEmail()) {
				this.showErrorAlert('Passwords do not match');
				return false;
			}
			return this.$refs.form.validate();
		},
		async createUser() {
			if (this.formIsValid()) {
				try {
					let res = await this.createUserAction({
						firstName: this.firstName,
						lastName: this.lastName,
						password: this.password,
						email: this.email,
					});
					if (res.success) {
						this.showSuccessAlert();
						this.$refs.form.reset();
					}
				} catch (error) {
					console.log(error);
				}
			}
		},

		showSuccessAlert() {
			this.alertSuccess = true;
		},

		showErrorAlert(message) {
			this.alertError = true;
			this.alertMessage = message;
			setTimeout(() => {
				this.alertError = false;
			}, 3000);
		},
	},
};
</script>
