import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                anchor: colors.cyan.base,
                primary: colors.cyan.base,
                secondary: colors.purple.base,
                accent: colors.indigo.accent3,
                error: colors.red.accent3,
                info: colors.lightBlue.accent3,
                success: colors.green.accent3,
                warning: colors.orange.accent3,
                text: '#414141',
            },
            dark: {
                anchor: colors.cyan.base,
                primary: colors.cyan.base,
                secondary: colors.purple.base,
                accent: colors.indigo.accent3,
                error: colors.red.accent3,
                info: colors.lightBlue.accent3,
                success: colors.green.accent3,
                warning: colors.orange.accent3,
                text: '#414141',
            },
        },
        light: true
    },
});