<template>
    <div class="pb-15">
        <Tab title="My Profile" />

        <v-container class="py-12">
            <v-card class="rounded-lg" max-width="700" width="100%">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                            Account details

                            <v-dialog
                                v-model="dialog"
                                overlay-opacity="0.9"
                                persistent
                                transition="fade-transition"
                                width="500"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey"
                                        icon
                                        style="
                                            position: absolute;
                                            top: 6px;
                                            right: 0.25rem;
                                        "
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>

                                <EditProfileDialog
                                    v-on:showDialog="showDialog"
                                />
                            </v-dialog>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row>
                                <v-col cols="12" sm="4" md="6"> Name </v-col>

                                <v-col
                                    class="grey--text"
                                    cols="12"
                                    sm="8"
                                    md="6"
                                >
                                    {{ getUser.firstName }}
                                </v-col>
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row>
                                <v-col cols="12" sm="4" md="6">
                                    Last name
                                </v-col>

                                <v-col
                                    class="grey--text"
                                    cols="12"
                                    sm="8"
                                    md="6"
                                >
                                    {{ getUser.lastName }}
                                </v-col>
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row>
                                <v-col cols="12" sm="4" md="6"> Email </v-col>

                                <v-col
                                    class="grey--text"
                                    cols="12"
                                    sm="8"
                                    md="6"
                                >
                                    {{ getUser.email }}
                                </v-col>
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row>
                                <v-col cols="12" sm="4" md="6">
                                    Password
                                </v-col>

                                <v-col
                                    class="grey--text"
                                    cols="12"
                                    sm="8"
                                    md="6"
                                >
                                    ********
                                </v-col>
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Tab from "@/components/Tab";
import EditProfileDialog from "@/components/myAccount/EditProfileDialog";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Tab,
        EditProfileDialog,
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        ...mapActions(["getUserAction"]),
        showDialog(datos) {
            this.dialog = datos;
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    async created() {
        await this.getUserAction();
    },
};
</script>
