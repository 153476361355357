<template>
	<div class="pb-15">
		<Tab title="Privacy Policies" />

		<v-container class="py-12">
			<v-card class="pa-12 rounded-lg" color="white" flat width="100%">
				<p class="font-weight-bold">
					Agreement between customer and Expedia, INC.
				</p>

				<p>
					Welcome to the Expedia.com website (the "Website"). This Website is
					provided solely to assist customers in gathering travel information,
					determining the availability of travel—related goods and services,
					making legitimate reservations or otherwise transacting business with
					travel suppliers, and for no other purposes. The terms "we", "us",
					"our", “Expedia” and "Expedia, Inc." refer to Expedia, Inc., a
					Washington corporation, and its subsidiaries and corporate affiliates,
					including Travelscape, LLC, (collectively, the "Expedia Companies").
					“Expedia Partner” means any co-branded and/or linked website through
					which we provide links, content or service. The term "you" refers to
					the customer visiting the Website and/or booking a reservation through
					us on this Website, or through our customer service agents.
				</p>

				<p>
					This Website is offered to you conditioned upon your acceptance
					without modification of all the terms, conditions, and notices set
					forth below (collectively, the "Terms of Use” or "Agreement"). Please
					read these Terms of Use carefully, as they contain important
					information about limitations of liability and resolution of disputes
					through arbitration rather than in court. You should also read
					our Privacy Policy, which also governs your use of the Website, and is
					incorporated by reference in this Agreement. By accessing or using
					this Website, booking any reservations for travel products or
					services, or contacting our call center agents, you agree that the
					Terms of Use then in force shall apply. If you do not agree to the
					Terms of Use, please do not use or book any reservations through this
					Website or our call center agents.
				</p>

				<p class="font-weight-bold">Use of the website</p>

				<p>As a condition of your use of this Website, you warrant that:</p>

				<ol class="mb-4">
					<li>you are at least 18 years of age;</li>
					<li>
						you possess the legal authority to create a binding legal
						obligation;
					</li>
					<li>
						you will use this Website in accordance with these Terms of Use;
					</li>
					<li>
						you will only use this Website to make legitimate reservations for
						you or for another person for whom you are legally authorized to
						act;
					</li>
					<li>
						you will inform such other persons about the Terms of Use that apply
						to the reservations you have made on their behalf, including all
						rules and restrictions applicable thereto;
					</li>
					<li>
						all information supplied by you on this Website is true, accurate,
						current and complete; and
					</li>
					<li>
						if you have an Expedia.com account, you will safeguard your account
						information and will supervise and be completely responsible for any
						use of your account by you and anyone other than you.
					</li>
				</ol>

				<p class="mb-0">
					We retain the right at our sole discretion to deny access to anyone to
					this Website and the services we offer, at any time and for any
					reason, including, but not limited to, for violation of these Terms of
					Use.
				</p>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import Tab from '@/components/Tab';
import Account from '../Account.vue';

export default {
	components: {
		Tab,
		Account,
	},
};
</script>
