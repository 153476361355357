<template>
    <v-autocomplete
        v-model="search"
        @change="saveState"
        @keyup="searchAirportFrom($event)"
        :background-color="color"
        :dense="dense"
        :filled="filled"
        :filter="filterObject"
        :items="airports"
        :label="text"
        :outlined="outlined"
        class="caret-hide rounded"
        color="cyan"
        item-text="name"
        placeholder="City or Airport"
        prepend-inner-icon="mdi-airplane"
        return-object
        rounded
    >
        <div class="px-7 py-3 font-weight-bold text-body-1" slot="no-data">
            Type at least 3 letters to search
        </div>

        <template v-slot:item="data">
            <div class="pa-3 text-body-1 font-weight">
                {{ data.item.name }}, {{ data.item.state }} -
                {{ data.item.country }} <strong>({{ data.item.iata }})</strong>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data: () => ({
        airports: [],
        search: "",
    }),
    props: ["color", "text", "value"],
    props: {
        color: {
            type: String,
        },
        text: {
            type: String,
        },
        value: {
            type: Object,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(["loadAirports", "actionFromSearch", "actionToSearch"]),
        async searchAirportFrom(event) {
            let value = event.target.value;
            if (value.length >= 3) {
                await this.loadAirports(value);
                this.airports = await this.getAirports;
            }
        },
        saveState() {
            if (this.text == "From") {
                this.actionFromSearch(this.search);
            } else {
                this.actionToSearch(this.search);
            }
        },
        filterObject(item, queryText) {
            if (typeof item == "object") {
                const city = item.city?.toLowerCase() ?? [];
                const country = item.country?.toLowerCase() ?? [];
                const iata = item.iata?.toLowerCase() ?? [];
                const icao = item.icao?.toLowerCase() ?? [];
                const name = item.name?.toLowerCase() ?? [];
                const state = item.state?.toLowerCase() ?? [];
                const searchText = queryText.toLowerCase();
                return (
                    city.indexOf(searchText) > -1 ||
                    country.indexOf(searchText) > -1 ||
                    iata.indexOf(searchText) > -1 ||
                    icao.indexOf(searchText) > -1 ||
                    name.indexOf(searchText) > -1 ||
                    state.indexOf(searchText) > -1
                );
            } else {
                return false;
            }
        },
    },
    computed: {
        ...mapGetters(["getAirports"]),
    },
    created() {
        if (this.value) {
            this.airports = [this.value];
            this.search = this.value;
        }
    },
};
</script>