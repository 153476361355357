<template>
    <div class="d-flex white">
        <v-container class="py-0">
            <v-row>
                <v-col>
                    <v-tabs color="cyan">
                        <v-tab
                            class="font-weight-bold grey--text text--darken-3"
                        >
                            {{ title }}
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        title: String,
    },
};
</script>

<style lang="scss" scoped>
.v-tab {
    cursor: auto;
    pointer-events: none;

    &::before {
        display: none;
    }
}
</style>