import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/plugins/Dayjs';
import VCalendar from 'v-calendar';


Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')