import Check from '../views/search/Check';
import CheckGDS from '../views/search/CheckGDS';
import Continue from '../views/search/Continue';
import Depart from '../views/search/Depart';
import Details from '../views/search/Details';
import Finalize from '../views/search/Finalize';
import Return from '../views/search/Return';

export default [
	{
		path: '/search/check',
		component: Check,
	},
	{
		path: '/search/checkGDS',
		component: CheckGDS,
	},
	{
		path: '/search/continue',
		component: Continue,
	},
	{
		path: '/search/depart',
		component: Depart,
	},
	{
		path: '/search/details',
		component: Details,
	},
	{
		path: '/search/finalize',
		component: Finalize,
	},
	{
		path: '/search/return',
		component: Return,
	},
];
