<template>
    <v-card class="overflow-hidden pa-6 rounded-lg transition-swing" flat>
        <v-row>
            <v-col cols="12" md="5">
                <div class="font-weight-bold text-body-1">Features:</div>

                <v-list disabled>
                    <v-list-item-group class="d-flex flex-wrap">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            class="col-6 cyan--text"
                            inactive
                        >
                            <v-list-item-icon
                                v-if="item.value in flight.vehicle.services"
                                class="mr-2"
                            >
                                <v-icon
                                    v-text="item.icon"
                                    small
                                    color="grey darken-4"
                                />
                            </v-list-item-icon>

                            <v-list-item-content
                                v-if="item.value in flight.vehicle.services"
                            >
                                <v-list-item-title v-text="item.text" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-divider class="d-none d-md-flex my-3" vertical />

            <v-col cols="12" md="7">
                <v-img
                    :src="getVehicleImage(flight.vehicle)"
                    aspect-ratio="2.2"
                    height="100%"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { basePathApi } from "@/services/axiosURI.service";

export default {
    data: () => ({
        checkbox: false,
        items: [
            { text: "Toilets", icon: "mdi-faucet", value: "toilet" },
            { text: "Wifi", icon: "mdi-wifi", value: "wifi" },
            { text: "AirCondit", icon: "mdi-wifi", value: "airConditioning" },
            { text: "Pets", icon: "mdi-wifi", value: "pets" },
        ],
    }),
    props: {
        flight: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getVehicleImage(item) {
            if (item.id ?? null) {
                return basePathApi + item.image1;
            }
            return "@/assets/flight__plane--front.jpg";
        },
    },
};
</script>
