const state = {
	flightFrom: JSON.parse(sessionStorage.getItem('flightFrom')) || null,
	flightTo: JSON.parse(sessionStorage.getItem('flightTo')) || null,
	fromType: JSON.parse(sessionStorage.getItem('fromType')),
	toType: JSON.parse(sessionStorage.getItem('toType')),
	adults: JSON.parse(sessionStorage.getItem('adults')) || 1,
	children: JSON.parse(sessionStorage.getItem('children')) || 0,
	lapInfants: JSON.parse(sessionStorage.getItem('lapInfants')) || 0,
	fromDate: JSON.parse(sessionStorage.getItem('fromDate')),
	toDate: JSON.parse(sessionStorage.getItem('toDate')),
	typeSearch: JSON.parse(sessionStorage.getItem('typeSearch')) || 'roundTrip', //Round trip or one way trip
	minPrice: JSON.parse(sessionStorage.getItem('minPrice')) || 1,
	maxPrice: JSON.parse(sessionStorage.getItem('maxPrice')) || 120000,
	searchParams: {
		sourceId: null,
		destinationId: null,
		limit: 10,
		pax: null,
		oneWay: sessionStorage.getItem('typeSearch'),
		dateDepart: null,
		// scales: 3,
		scales: 0,
		dateReturn: null,
		departureClass: 'ALL',
		arrivalClass: 'ALL',
		orderBy: 'PRICE',
		minPrice: JSON.parse(sessionStorage.getItem('minPrice')) || 1,
		maxPrice: JSON.parse(sessionStorage.getItem('maxPrice')) || 120000,
	},
};

// actions
const actions = {
	async updateFilter({ commit }, { data, value, refresh = false }) {
		commit('setUpdateFilter', { data, value, refresh });
	},

	flightFromAction({ commit }, data) {
		commit('setFlightFrom', data);
	},

	flightToAction({ commit }, data) {
		commit('setFlightTo', data);
	},

	adultsAction({ commit }, data) {
		commit('setAdults', data);
	},

	childrenAction({ commit }, data) {
		commit('setChildren', data);
	},

	lapInfantsAction({ commit }, data) {
		commit('setLapInfants', data);
	},
	fromDateAction({ commit }, data) {
		commit('setFromDate', data);
	},

	toDateAction({ commit }, data) {
		commit('setToDate', data);
	},

	categoryAction({ commit }, data) {
		commit('setCategory', data);
	},

	typeSearchAction({ commit }, data) {
		commit('setTypeSearch', data);
	},

	actionMinPrice({ commit }, data) {
		commit('setMinPrice', data);
	},

	actionMaxPrice({ commit }, data) {
		commit('setMaxPrice', data);
	},

	actionFromType({ commit }, data) {
		commit('setFromType', data);
	},

	actionToType({ commit }, data) {
		commit('setToType', data);
	},
};

// mutations
const mutations = {
	setFromType(state, type) {
		sessionStorage.setItem('fromType', JSON.stringify(type));
		state.fromType = type;
	},

	setToType(state, type) {
		sessionStorage.setItem('toType', JSON.stringify(type));
		state.toType = type;
	},

	setUpdateFilter(state, { data, value, refresh }) {
		state.searchParams[value] = data;
	},

	setFlights(state, flights) {
		state.flights = flights;
	},

	setFlightFrom(state, flight) {
		sessionStorage.setItem('flightFrom', JSON.stringify(flight));
		state.flightFrom = flight;
	},

	setFlightTo(state, flight) {
		sessionStorage.setItem('flightTo', JSON.stringify(flight));
		state.flightTo = flight;
	},

	setAdults(state, data) {
		if (data >= 0) {
			sessionStorage.setItem('adults', JSON.stringify(data));
			state.adults = data;
		}
	},

	setChildren(state, data) {
		if (data >= 0) {
			sessionStorage.setItem('children', JSON.stringify(data));
			state.children = data;
		}
	},

	setLapInfants(state, data) {
		if (data >= 0) {
			sessionStorage.setItem('lapInfants', JSON.stringify(data));
			state.lapInfants = data;
		}
	},

	setFromDate(state, data) {
		sessionStorage.setItem('fromDate', JSON.stringify(data));
		state.fromDate = data;
	},

	setToDate(state, data) {
		sessionStorage.setItem('toDate', JSON.stringify(data));
		state.toDate = data;
	},

	setTypeSearch(state, data) {
		sessionStorage.setItem('typeSearch', JSON.stringify(data));
		state.typeSearch = data;
	},

	setMaxPrice(state, data) {
		sessionStorage.setItem('maxPrice', JSON.stringify(data));
		state.maxPrice = data;
	},

	setMinPrice(state, data) {
		state.minPrice = data;
		sessionStorage.setItem('minPrice', JSON.stringify(data));
	},
};

// getters
const getters = {
	getFlightFrom: (state) => state.flightFrom,
	getFlightTo: (state) => state.flightTo,
	getAdults: (state) => state.adults,
	getChildren: (state) => state.children,
	getLapInfants: (state) => state.lapInfants,
	getTotalPassenger: (state) => {
		if (
			state.adults != null ||
			state.children != null ||
			state.lapInfants != null
		) {
			return (
				parseInt(state.adults) +
				parseInt(state.children) +
				parseInt(state.lapInfants)
			);
		} else {
			return 0;
		}
	},
	getFromDate: (state) => state.fromDate,
	getToDate: (state) => state.toDate,
	getTypeSearch: (state) => state.typeSearch,
	getMinPrice: (state) => state.minPrice,
	getMaxPrice: (state) => state.maxPrice,
	getFromType: (state) => state.fromType,
	getToType: (state) => state.toType,
};

export default {
	state,
	getters,
	actions,
	mutations,
};
