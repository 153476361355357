<template>
	<v-card class="overflow-hidden pa-6 rounded-lg transition-swing" flat>
		<v-row>
			<v-col class="d-flex flex-column flex-wrap pr-md-12" cols="12" md="8">
				<v-row>
					<v-col class="d-flex" cols="12" md="8">
						<v-row>
							<v-col class="font-weight-bold text-h6" cols="12" md="4">
								{{ getDate(flightData.departureDate) }}
							</v-col>

							<v-col class="d-flex flex-column" cols="12" md="8">
								<div class="d-flex text-body-1">
									<span class="col-2 pa-0"> From </span>

									<span class="font-weight-bold">
										{{ flightData.source.state }} ({{ flightData.source.code }})
									</span>
								</div>

								<div class="d-flex text-body-1">
									<span class="col-2 pa-0"> To </span>

									<span class="font-weight-bold">
										{{ flightData.destination.state }}
										({{ flightData.destination.code }})
									</span>
								</div>
							</v-col>
						</v-row>
					</v-col>

					<v-col class="text-md-right" cols="12" md="4">
						<div
							class="align-start d-flex font-weight-bold justify-start justify-md-end mb-6 mb-md-0 text-body-2"
							cols="12"
							md="5"
							style="margin-right: -33px"
						>
							<div class="d-flex flex-column">
								<span>
									{{ flight.vehicle.detail.brand }}
								</span>

								<span>
									{{ flight.vehicle.detail.model }}
								</span>
							</div>

							<v-img
								src="@/assets/trips__logo--ventus.svg"
								class="mt-1 ml-2"
								contain
								height="25px"
								max-width="25px"
							/>
						</div>

						<div class="font-weight-bold cyan--text text-body-1">
							{{ flight.Segment }}
						</div>
					</v-col>

					<v-col
						class="align-md-end d-flex flex-column flex-md-row justify-space-between"
						cols="12"
					>
						<div class="mb-6 mb-md-0 trip__grid text-body-1">
							<div class="trip__grid--where-from text-body-2">
								{{ getDateFormat(flight.flightDate) }}
							</div>

							<div class="trip__grid--time-from">
								{{ getHour(flightData.departureDate) }}
							</div>

							<div
								v-if="flight.scales == 0"
								class="trip__grid--type font-weight-bold green--text text--lighten-1 text-center"
							>
								Nonstop
							</div>
							<div
								v-if="flight.scales == 1"
								class="trip__grid--type font-weight-bold green--text text--lighten-1 text-center"
							>
								1 Scale
							</div>
							<div
								v-if="flight.scales > 1"
								class="trip__grid--type font-weight-bold green--text text--lighten-1 text-center"
							>
								{{ flightData.scales }} Scales
							</div>

							<div class="trip__grid--where-to text-body-2">
								{{ getDateFormat(flightData.arrivalDate) }}
							</div>
							<div class="trip__grid--time-to">
								{{ getHour(flightData.departureDate) }}
							</div>
						</div>

						<div class="font-weight-bold text-body-2">
							Duration:
							{{ parseDuration(flightData.flightTime) }}
						</div>
					</v-col>

                    <v-col
                        class="align-md-end d-flex flex-column flex-md-row justify-space-between"
                        cols="12"
                    >
                        <v-card
                            class="d-flex align-center flex-row justify-space-between pa-3 rounded-lg"
                            color="green lighten-5"
                            flat
                            width="100%"
                        >
                            <div class="mr-3 green--text text--darken-3">
                                Greenhouse gas emissions for this flight are
                                compensated by V1
                            </div>

                            <v-spacer />

                            <v-img
                                contain
                                height="44px"
                                max-width="44px"
                                src="@/assets/badge__v1--green.png"
                                style="border-radius: 50%"
                                width="44px"
                            />

                            <v-spacer />
                        </v-card>
                    </v-col>
				</v-row>
			</v-col>

			<v-col class="d-none d-md-flex justify-center my-n3 py-0" cols="1">
				<v-divider vertical style="border-style: dashed" />
			</v-col>

			<v-col
				class="d-flex d-md-none mx-n3 px-0"
				cols="12"
				style="flex: auto; max-width: calc(100% + 24px)"
			>
				<v-divider style="border-style: dashed" />
			</v-col>

			<v-col
				v-if="showPrice"
				class="d-flex flex-column pl-md-5"
				cols="12"
				md="3"
			>
				<div class="font-weight-bold text-h6">
					{{ flight.currency }} ${{ flight.price }}
				</div>

				<div class="align-center d-flex text-body-2">
					<div class="pr-2">Price complete plane</div>

					<div class="align-center d-flex cyan--text">
						<v-icon color="cyan"> mdi-seat-passenger </v-icon>
						{{ flight.paxAvailable }}
					</div>
				</div>

				<div class="align-center d-flex mt-auto">
					<a @click="editDeparture()" class="mr-1"> Change </a>
					|
					<a class="ml-1"> Show details </a>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	methods: {
		getDate(date) {
			return this.dayjs(date, 'YYYY-MM-DD:HH:mm:ss').utc().format('ddd, MMM D');
		},
		getHour(date) {
			return this.dayjs(date).utc().format('HH:mm');
		},
		getDateFormat(date) {
			return this.dayjs(date).utc().format('MM/DD/YY');
		},
		parseDuration(duration) {
			let timeTotal = this.dayjs.duration(duration, 'm');
			return `${timeTotal.hours()} hs ${timeTotal.minutes()} min`;
		},
		editDeparture() {
			if (this.type == 'Depart') {
				this.$router.push('/search/depart');
			} else {
                if (this.$route.path !== '/search/return') {
                    this.$router.push('/search/return');
                }
			}
		},
	},
	props: {
		flight: {
			type: Object,
			required: true,
		},
		flightData: {
			type: Object,
			required: true,
		},
		showPrice: {
			type: Boolean,
			required: false,
			default: true,
		},
		type: {
			type: String,
			required: false,
			default: 'Depart',
		},
	},
};
</script>

<style lang="scss" scoped>
.trip__grid {
	width: 100%;

	@media screen and (min-width: 960px) {
		width: 50%;
	}
}
</style>
