<template>
    <v-card class="pt-9 px-3" color="white" flat rounded="0">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="font-weight-bold text-h6">Deals of the day</div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            to="/deals"
                            :elevation="hover ? 6 : 0"
                            class="
                                overflow-hidden
                                rounded-lg
                                text-decoration-none
                                transition-swing
                            "
                            color="grey lighten-5"
                            flat
                            width="100%"
                        >
                            <v-img
                                class="align-center justify-center text-center"
                                height="250px"
                                src="@/assets/deals__nature--mendoza.jpg"
                            />

                            <v-card-title
                                class="font-weight-bold pb-0 text-h6 cyan--text"
                            >
                                Fly to Mendoza
                            </v-card-title>

                            <v-card-text>
                                <p class="text-body-1 black--text">
                                    Up to 25% discounts!
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>

                <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            to="/deals"
                            :elevation="hover ? 6 : 0"
                            class="
                                overflow-hidden
                                rounded-lg
                                text-decoration-none
                                transition-swing
                            "
                            color="grey lighten-5"
                            flat
                            width="100%"
                        >
                            <v-img
                                class="align-center justify-center text-center"
                                height="250px"
                                src="@/assets/deals__plane--outside.jpg"
                            />

                            <v-card-title
                                class="font-weight-bold pb-0 text-h6 cyan--text"
                            >
                                Fly to Mendoza
                            </v-card-title>

                            <v-card-text>
                                <p class="text-body-1 black--text">
                                    Up to 25% discounts!
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>

                <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            to="/deals"
                            :elevation="hover ? 6 : 0"
                            class="
                                overflow-hidden
                                rounded-lg
                                text-decoration-none
                                transition-swing
                            "
                            color="grey lighten-5"
                            flat
                            width="100%"
                        >
                            <v-img
                                class="align-center justify-center text-center"
                                height="250px"
                                src="@/assets/deals__plane--inside.jpg"
                            />

                            <v-card-title
                                class="font-weight-bold pb-0 text-h6 cyan--text"
                            >
                                Fly to Mendoza
                            </v-card-title>

                            <v-card-text>
                                <p class="text-body-1 black--text">
                                    Up to 25% discounts!
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>

                <v-col class="d-flex justify-center" cols="12">
                    <v-btn to="/deals" color="cyan" text>View more deals</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {},
    data: () => ({
        toggle: "roundTrip",
        dealsToday: 4,
        fakeImage: require("@/assets/deals__nature--mendoza.jpg"),
    }),
    computed: {
        ...mapGetters(["getOffers", "isLoggedIn"]),
    },
};
</script>