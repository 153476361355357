<template>
	<div class="pb-15">
		<Tab title="Contact Us" />

		<v-container class="py-12">
			<v-card class="rounded-lg" max-width="700" width="100%">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="font-weight-bold">
							Contact information
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-divider />

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<v-row>
								<v-col cols="12" sm="4" md="6"> Email </v-col>

								<v-col class="font-weight-bold" cols="12" sm="8" md="6">
									<a href="mailto:info@jetsbooking.com">
										info@jetsbooking.com
									</a>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-divider />

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<v-row>
								<v-col cols="12" sm="4" md="6"> Telephone </v-col>

								<v-col class="font-weight-bold" cols="12" sm="8" md="6">
									<a href="tel:+541152184851"> (+54) 11 5218-4851 </a>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-divider />

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<v-row>
								<v-col cols="12" sm="4" md="6"> Web site </v-col>

								<v-col class="font-weight-bold" cols="12" sm="8" md="6">
									<a href="/"> www.jetsbooking.com </a>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import Tab from '@/components/Tab';

export default {
	components: {
		Tab,
	},
};
</script>
