import { mainAxios, usersApi } from '@/services/axiosURI.service';

const state = {
	user: {
		firstName: 'Test',
		lastName: 'User',
		email: 'email@user.com',
	},
};

// actions
const actions = {
	async getUserAction({ commit }, data) {
		try {
			const response = await mainAxios.get('/userData');
			// const { data } = response.data;
			// commit('setUser', data);
		} catch (error) {
			console.error(error);
			// commit('setUser', []);
		}
	},

	async updateUserAction({ commit }, data) {
		try {
			const response = await usersApi.updateUser({
				data: { attributes: data },
			});
			// const { data } = response.data;
			// commit('setUser', data);
		} catch (error) {
			console.error(error);
			// commit('setUser', []);
		}
	},

	async createUserAction({ commit }, data) {
		try {
			const response = await usersApi.createUser({
				data: { attributes: data },
			});

			if (response.data) {
				commit('setUser', data);
				return { success: true };
			} else {
				return { success: false };
			}
		} catch (error) {
			console.error(error);
			// commit('setUser', []);
		}
	},
};

// mutations
const mutations = {
	setUser(state, data) {
		state.user = data;
	},
};

// getters
const getters = {
	getUser: (state) => state.user,
};
export default {
	state,
	getters,
	actions,
	mutations,
};
