<template>
	<div class="pb-15">
		<div class="d-flex white">
			<v-container class="py-0">
				<v-row>
					<v-col>
						<v-tabs v-model="tab">
							<v-tab
								class="font-weight-bold grey--text text--darken-3"
								href="#notifications"
							>
								Notifications
							</v-tab>

							<v-tab
								class="font-weight-bold grey--text text--darken-3"
								href="#alerts"
							>
								My Alerts
							</v-tab>
						</v-tabs>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<v-container class="py-12">
			<v-tabs-items
				v-model="tab"
				style="
					background-color: transparent;
					max-width: 700px;
					overflow: visible;
					width: 100%;
				"
			>
				<v-tab-item value="notifications" transition="false">
					<v-card v-model="notificationItems" class="rounded-lg" width="100%">
						<v-list v-for="(item, i) in items1" :key="i">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title
										v-text="item.title"
										class="col-8 col-sm-10 font-weight-bold pa-0"
									/>

									<div
										v-text="item.date"
										class="col-4 col-sm-2 mb-0 pa-0 text-body-2 text-right"
										color="grey"
									/>

									<v-list-item-subtitle
										v-text="item.text"
										class="col-10 mt-1 pa-0 text-body-1"
									/>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-tab-item>

				<v-tab-item value="alerts" transition="false">
					<div class="d-flex flex-column">
						<v-hover v-for="(item, i) in items2" v-slot="{ hover }">
							<v-card
								@click=""
								:elevation="hover ? 6 : 0"
								:key="i"
								class="mb-6 rounded-lg transition-swing"
								width="100%"
							>
								<v-list>
									<v-list-item>
										<v-list-item-content>
											<v-list-item-title class="align-center d-flex">
												<v-icon class="mr-1" color="cyan">
													mdi-airplane
												</v-icon>

												Round Trip
											</v-list-item-title>

											<v-list-item-subtitle
												class="align-center d-flex font-weight-bold pl-7 text-body-1"
											>
												Buenos Aires

												<v-icon class="grey--text text--darken-2 mx-3" small>
													mdi-arrow-right
												</v-icon>

												Miami
											</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
						</v-hover>

						<v-dialog
							v-model="dialog"
							overlay-opacity="0.9"
							persistent
							transition="fade-transition"
							width="800"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									class="font-weight-bold ml-auto"
									color="cyan"
									dark
									elevation="0"
									x-large
								>
									Create alert
								</v-btn>
							</template>

							<CreateAlertDialog v-on:showDialog="showDialog" />
						</v-dialog>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-container>
	</div>
</template>

<script>
import Tab from '@/components/Tab';
import CreateAlertDialog from '@/components/myAccount/CreateAlertDialog';

export default {
	components: {
		Tab,
		CreateAlertDialog,
	},
	data: () => ({
		dialog: false,
		text: 'roundTrip',
		tab: 'notifications',
		notificationItems: 3,
		items1: [
			{
				title: 'Night Sale App',
				date: '20 sep. 2018',
				text: '6 installments without interest with Visa in accommodations and packages to the Caribbean and Brazil',
			},
			{
				title: 'Night Sale App',
				date: '8 aug. 2018',
				text: '6 installments without interest with Visa in accommodations and packages to the Caribbean and Brazil',
			},
			{
				title: 'Buy your flight today',
				date: '18 apr. 2018',
				text: '$4,000 off flights to Miami, New York, Cancun and Punta Cana Airlines Argentines. In addition, 12 installments without interest with Visa.',
			},
		],
		alertItems: 6,
		items2: [
			{
				title: 'Round Trip',
			},
			{
				title: 'Round Trip',
			},
			{
				title: 'Round Trip',
			},
			{
				title: 'Round Trip',
			},
		],
	}),
	methods: {
		showDialog(datos) {
			this.dialog = datos;
		},
	},
};
</script>

<style lang="scss" scoped>
#notifications {
	.v-list {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);

		&:last-child {
			border-bottom: none;
		}
	}
}

#alerts .v-card {
	overflow: hidden;

	&::before {
		background: #00bcd4;
		content: '';
		opacity: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 10px;
	}
}
</style>
