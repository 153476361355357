<template>
    <v-card class="px-3 py-16" color="white" flat rounded="0">
        <v-container>
            <v-row>
                <v-col cols="12" md="7">
                    <div class="font-weight-bold mb-3 text-h4">
                        What does JetsBooking do?
                    </div>

                    <div class="mb-6 text-h6">
                        JetsBooking connects qualified travel agents from all
                        around the world with certified aviation companies, to
                        offer their passengers the absolute best flying options
                        combined with commercial airways.
                    </div>

                    <v-dialog v-model="dialog" width="800">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="cyan--text"
                                color="cyan lighten-5"
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                            >
                                View promotional video
                            </v-btn>
                        </template>

                        <v-card class="d-flex rounded-lg" dark>
                            <video
                                id="promotional_video"
                                autoplay
                                controls=""
                                width="100%"
                            >
                                <source
                                    type="video/mp4"
                                    src="@/assets/promotional_video.mp4"
                                />
                            </video>
                        </v-card>
                    </v-dialog>
                </v-col>

                <v-spacer class="d-none d-md-block" />

                <v-divider class="d-none d-md-block" vertical />

                <v-divider class="d-md-none mx-3 my-9" />

                <v-spacer class="d-none d-md-block" />

                <v-col cols="12" md="4">
                    <div class="font-weight-bold mb-3 text-h4">Follow Us</div>

                    <div class="text-h6 mb-3">
                        Get the latest news and travel inspiration.
                    </div>

                    <div>
                        <v-btn
                            href="https://www.facebook.com/jetsbooking"
                            class="text-decoration-none"
                            color="grey darken-3"
                            icon
                            target="_blank"
                            x-large
                        >
                            <v-icon> mdi-facebook </v-icon>
                        </v-btn>

                        <v-btn
                            href="https://www.instagram.com/jetsbooking/"
                            class="text-decoration-none"
                            color="grey darken-3"
                            icon
                            target="_blank"
                            x-large
                        >
                            <v-icon> mdi-instagram </v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
    watch: {
        dialog: function (newValue, old) {
            setTimeout(() => {
                let video = document.getElementById("promotional_video");
                if (!newValue) {
                    video.pause();
                } else {
                    video.currentTime = 0;
                    video.play();
                }
            }, 300);
        },
    },
};
</script>