<template>
	<div class="pb-15">
		<Tab title="Deals" />

		<v-card
			v-if="getOffers.length > 0"
			v-model="bestDeals"
			color="white"
			flat
			rounded="0"
		>
			<v-container class="py-12">
				<v-row>
					<v-col cols="12">
						<div v-if="country" class="font-weight-bold text-h6">
							Best deals to {{ country }}
						</div>

						<div v-else class="font-weight-bold text-h6">Best deals</div>
					</v-col>

					<v-col v-for="(item, i) in getOffers" :key="i" cols="6" md="4">
						<v-hover v-slot="{ hover }">
							<v-card
								@click="dealDetails(item)"
								class="overflow-hidden rounded-lg text-decoration-none transition-swing"
								color="grey lighten-5"
								flat
								width="100%"
								:elevation="hover ? 6 : 0"
							>
								<v-img
									class="align-center justify-center text-center"
									gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)"
									height="200px"
									:src="getVehicleImage(item)"
								>
									<v-img
										class="d-none d-md-flex mt-n16 mt-md-0 mx-0 mx-md-auto"
										contain
										height="65px"
										max-width="65px"
										src="@/assets/logo__iso--white.svg"
										:class="item.logoClass"
									/>

									<v-card-title
										v-text="'$' + item.currency + ' ' + item.price"
										class="font-weight-bold justify-center py-0 text-h5 white--text"
									/>

									<p
										v-text="item.source.state + ' - ' + item.destination.state"
										:class="fakeClass"
									/>
									<p
										v-text="getDateFormat(item.flightDate)"
										:class="fakeClass"
									/>
								</v-img>
							</v-card>
						</v-hover>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-card v-else color="transparent" flat rounded="0">
			<v-container class="py-12">
				<v-row align="start" justify="space-between">
					<v-col class="mb-12 mb-md-0" cols="12" md="5">
						<p class="mb-1"><strong>Sorry</strong>...</p>

						<p class="mb-6">
							There are no deals available at the moment from JetsBooking.
							Please check again soon.
						</p>

						<v-btn to="/flights" class="font-weight-bold" color="cyan" outlined>
							<v-icon left> mdi-arrow-left </v-icon>

							Back to Flights
						</v-btn>
					</v-col>

					<v-col cols="12" md="5">
						<v-img
							alt="No deals found"
							contain
							src="@/assets/undraw_airport_re_oqk1.svg"
							transition="scale-transition"
							width="100%"
						/>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
import Tab from '@/components/Tab';
import { mapGetters, mapActions } from 'vuex';
import { basePathApi } from '@/services/axiosURI.service';

export default {
	components: {
		Tab,
	},
	props: ['country'],
	data: () => ({
		bestDeals: 6,
		fakeImage: require('@/assets/deals__plane--outside.jpg'),
		fakeClass: 'font-weight-bold mb-3 text-body-1 white--text',
		items: [
			{
				image: require('@/assets/deals__plane--outside.jpg'),
				logoClass: 'mt-n16 mt-md-0 mx-0 mx-md-auto',
				title: 'Special price',
				text: 'Discounts on selected flights',
				descClass: 'font-weight-bold mb-3 text-body-1 white--text',
			},
			{
				image: require('@/assets/deals__plane--inside.jpg'),
				logoClass: 'mt-n16 mt-md-0 mx-0 mx-md-auto',
				title: 'Special price',
				text: 'Discounts on selected flights',
				descClass: 'font-weight-bold mb-3 text-body-1 white--text',
			},
			{
				image: require('@/assets/deals__nature--mendoza.jpg'),
				logoClass: 'd-md-none mt-n16 mx-0',
				title: 'Flights to New York',
				text: '',
				descClass: 'font-weight-bold mb-0 text-body-1 white--text',
			},
			{
				image: require('@/assets/deals__plane--outside.jpg'),
				logoClass: 'mt-n16 mt-md-0 mx-0 mx-md-auto',
				title: 'Special price',
				text: 'Discounts on selected flights',
				descClass: 'font-weight-bold mb-3 text-body-1 white--text',
			},
			{
				image: require('@/assets/deals__plane--inside.jpg'),
				logoClass: 'mt-n16 mt-md-0 mx-0 mx-md-auto',
				title: 'Special price',
				text: 'Discounts on selected flights',
				descClass: 'font-weight-bold mb-3 text-body-1 white--text',
			},
			{
				image: require('@/assets/deals__plane--outside.jpg'),
				logoClass: 'd-md-none mt-n16 mx-0',
				title: 'Flights to Los Angeles',
				text: 'Hollywood',
				descClass: 'font-weight-bold mb-0 text-body-1 white--text',
			},
		],
	}),
	computed: {
		...mapGetters(['getOffers']),
	},
	methods: {
		...mapActions([
			'loadOffersFilter',
			'loadOffers',
			'actionFromSearch',
			'actionToSearch',
			'loadAirport',
			'typeSearchAction',
			'fromDateAction',
			'toDateAction',
		]),
		getVehicleImage(item) {
			if (item.vehicle?.id ?? null) {
				return basePathApi + item.vehicle.image1;
			}
			return '';
		},
		getHour(date) {
			return this.dayjs(date).format('HH:mm');
		},
		getDateFormat(date) {
			let format = 'ddd, DD MMM, YYYY';

			return this.dayjs(date).format(format);
		},
		async dealDetails(item) {
			let source = await this.loadAirport(item.source.id);
			let destination = await this.loadAirport(item.destination.id);
			this.actionFromSearch(source);
			this.actionToSearch(destination);
			this.typeSearchAction('oneWay');
			this.fromDateAction(
				this.dayjs(item.flightDate).format('YYYY-MM-DD HH:mm')
			);
			this.toDateAction(this.dayjs(item.flightDate).format('YYYY-MM-DD HH:mm'));
			this.$router.push('/search/depart');
		},
	},
	async created() {
		if (this.country) {
			await this.loadOffersFilter({
				limit: 10,
				page: 1,
				country: this.country,
			});
		} else {
			await this.loadOffers();
		}
	},
};
</script>
