<template>
	<v-menu :close-on-content-click="false" offset-y min-width="360">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-bind="attrs"
				v-on="on"
				:background-color="color"
				:value="getTotalPassenger + ' Passengers'"
				class="rounded"
				filled
				label="Passenger"
				prepend-inner-icon="mdi-account"
				readonly
				rounded
			/>
		</template>

		<v-card class="px-0 py-2" flat rounded="0" width="100%">
			<v-row class="ma-0">
				<v-col class="px-7 pt-3" cols="12">
					<div class="font-weight-bold mb-3 text-body-1">Passengers</div>

					<v-row class="align-center ma-0">
						<v-col class="pa-0" cols="6">
							<div
								class="font-weight-bold text-body-2 grey--text text--darken-4"
							>
								Adults
							</div>

							<div class="text-body-2 grey--text text--darken-4">12+ years</div>
						</v-col>

						<v-col class="align-center d-flex justify-end pa-0" cols="6">
							<v-btn icon small>
								<v-icon @click="decrement('adults')"> mdi-minus </v-icon>
							</v-btn>

							<v-otp-input
								:value="String(getAdults)"
								class="mx-3"
								dense
								length="1"
								max="9"
								min="0"
								style="max-width: 60px"
								type="number"
							/>

							<v-btn icon small>
								<v-icon @click="increment('adults')"> mdi-plus </v-icon>
							</v-btn>
						</v-col>
					</v-row>

					<v-row class="align-center ma-0">
						<v-col class="pa-0" cols="6">
							<div
								class="font-weight-bold text-body-2 grey--text text--darken-4"
							>
								Childrens
							</div>

							<div class="text-body-2 grey--text text--darken-4">
								2-11 years
							</div>
						</v-col>

						<v-col class="align-center d-flex justify-end pa-0" cols="6">
							<v-btn icon small>
								<v-icon @click="decrement('children')"> mdi-minus </v-icon>
							</v-btn>

							<v-otp-input
								:value="String(getChildren)"
								class="mx-3"
								dense
								length="1"
								max="9"
								min="0"
								type="number"
								style="max-width: 60px"
							/>

							<v-btn icon small>
								<v-icon @click="increment('children')"> mdi-plus </v-icon>
							</v-btn>
						</v-col>
					</v-row>

					<v-row class="align-center ma-0">
						<v-col class="pa-0" cols="6">
							<div
								class="font-weight-bold text-body-2 grey--text text--darken-4"
							>
								Infants
							</div>

							<div class="text-body-2 grey--text text--darken-4">
								Under 2 years
							</div>
						</v-col>

						<v-col class="align-center d-flex justify-end pa-0" cols="6">
							<v-btn icon small>
								<v-icon @click="decrement('lapInfants')"> mdi-minus </v-icon>
							</v-btn>

							<v-otp-input
								:value="String(getLapInfants)"
								class="mx-3"
								dense
								length="1"
								max="9"
								min="0"
								type="number"
								style="max-width: 60px"
							/>

							<v-btn icon small>
								<v-icon @click="increment('lapInfants')"> mdi-plus </v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card>
	</v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	data: () => ({
		children: 0,
		adults: 0,
		lapInfants: 0,
		total: 0,
	}),
	props: {
		color: {
			type: String,
			default: 'white',
		},
	},
	computed: {
		...mapGetters([
			'getAdults',
			'getChildren',
			'getLapInfants',
			'getTotalPassenger',
		]),
	},
	methods: {
		...mapActions(['childrenAction', 'adultsAction', 'lapInfantsAction']),
		increment(element) {
			if (this[element] <= 8) {
				this[element]++;
				let name = element + 'Action';
				this[name](this[element]);
			}
		},
		decrement(element) {
			if (this[element] != 0) {
				this[element]--;
				let name = element + 'Action';
				this[name](this[element]);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.v-menu__content {
	margin-top: -8px !important;
}
</style>
