import {commonAxios, flightsApi, mainAxios, mockup} from '@/services/axiosURI.service';

const state = {
	offers: [],
	offersCountry: [],
};

// actions
const actions = {
	//Busqueda con parametros (PAIS)
	async loadOffersFilter({ commit }, data) {
		try {
			const response = await mainAxios.post('searchOffers', data);
			commit('setOffers', response.data.data.message);
		} catch (error) {
			console.error(error);
		}
	},

	//Busqueda SIN parametros (Todas las ofertas)
	async loadOffers({ commit }) {
		try {
			// TODO: descomentar
			// const response = await mainAxios.post('searchOffers');
			// if (Array.isArray(response.data.data.message)) {
			//   commit('setOffers', response.data.data.message)
			// } else {
			//   commit('setOffers', [])
			// }
			let sourceId = 6995;
			let limit = 10;
			const response = await flightsApi.getDeals(sourceId, limit);
			commit('setOffers', response.data.data.trips)
		} catch (error) {
			console.error(error);
		}
	},

	async loadOffersCountry({ commit }) {
		try {
			// TODO: descomentar
			// const response = await mainAxios.post('searchOffersCountries');
			// //LA API ESTA MAL. RETORNA AL REVES DE COMO DEBERIA (STATUS y DATA)
			// if (Array.isArray(response.data.status.message)) {
			//   commit('setOffersCountry', response.data.status.message)
			// } else {
			//   commit('setOffersCountry', [])
			// }
		} catch (error) {
			console.error(error);
		}
	},
};

// mutations
const mutations = {
	setOffers(state, offers) {
		state.offers = offers;
	},

	setOffersCountry(state, offersCountry) {
		state.offersCountry = offersCountry;
	},
};

// getters
const getters = {
	getOffers: (state) => {
		return state.offers;
	},

	getOffersCountry: (state) => {
		return state.offersCountry;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
