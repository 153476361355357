<template>
	<v-container class="mb-15 pt-12 pb-9">
		<v-row class="insert-data justify-space-between">
			<v-col cols="12" md="8">
				<v-card class="pa-6" flat>
					<v-row>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							class="col-12 pa-0"
							style="width: 100%"
						>
							<v-col cols="12">
								<div class="font-weight-bold text-body-1">Who's traveling?</div>
							</v-col>

							<v-col
								v-for="index in getAdults"
								:key="'adult' + index"
								class="pb-0"
								cols="12"
							>
								<div class="font-weight-bold text-body-2">
									Passenger {{ index }}: Adult
								</div>

								<v-row class="my-0">
									<v-col cols="12" md="6">
										<v-text-field
											v-model="payment.passengers[index - 1].name"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Name"
											placeholder="Jane"
											rounded
											:rules="required3"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-text-field
											v-model="payment.passengers[index - 1].lastName"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Last Name"
											placeholder="Doe"
											rounded
											:rules="required3"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-menu
											v-model="modalDateAdults[index]"
											:close-on-content-click="true"
											min-width="auto"
											nudge-right="40"
											offset-y
											transition="scale-transition"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="payment.passengers[index - 1].dateOfBirth"
													v-bind="attrs"
													v-on="on"
													background-color="grey lighten-4"
													class="rounded"
													filled
													label="Date of Birth (MM/DD/YYYY)"
													rounded
													:rules="required8"
													readonly
												/>
											</template>
											<vc-date-picker
												v-model="payment.passengers[index - 1].dateOfBirth"
												:columns="
													$screens({
														default: 1,
														lg: 2,
													})
												"
												:model-config="modelConfig"
												:is-range="false"
												mode="date"
												style="border: none; border-radius: 4px; width: 100%"
											/>
										</v-menu>
									</v-col>

									<v-col cols="12" md="6">
										<v-select
											v-model="payment.passengers[index - 1].country"
											:items="residence"
											:menu-props="{
												offsetY: true,
											}"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Country of Residence"
											rounded
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-select
											v-model="payment.passengers[index - 1].typeDocument"
											:items="idtype"
											:menu-props="{
												offsetY: true,
											}"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="ID type"
											rounded
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-text-field
											v-model="payment.passengers[index - 1].documentNumber"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="ID number"
											placeholder="ID number"
											rounded
											:rules="required8"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<div class="font-weight-bold text-body-2">Gender</div>

										<v-radio-group
											v-model="payment.passengers[index - 1].gender"
											row
											:rules="required"
										>
											<v-radio label="Male" value="male" />

											<v-radio label="Female" value="female" />
										</v-radio-group>
									</v-col>
								</v-row>
								<v-divider />
							</v-col>

							<v-col
								v-for="index in getChildren"
								:key="'children' + index"
								class="pb-0"
								cols="12"
							>
								<div class="font-weight-bold text-body-2">
									Passenger {{ index }}: Children
								</div>

								<v-row class="my-0">
									<v-col cols="12" md="6">
										<v-text-field
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Name"
											placeholder="Jane"
											rounded
											:rules="required3"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-text-field
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Last Name"
											placeholder="Doe"
											rounded
											:rules="required3"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-menu
											v-model="modalDateChildren[index]"
											:close-on-content-click="false"
											min-width="auto"
											nudge-right="40"
											offset-y
											transition="scale-transition"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-bind="attrs"
													v-on="on"
													background-color="grey lighten-4"
													class="rounded"
													filled
													label="Date of Birth (MM/DD/YYYY)"
													rounded
													:rules="required8"
												/>
											</template>

											<v-date-picker
												v-model="dateChildren[index]"
												@input="modalDateChildren[index] = false"
												color="cyan"
												header-color="cyan"
												no-title
											/>
										</v-menu>
									</v-col>

									<v-col cols="12" md="6">
										<v-select
											:items="residence"
											:menu-props="{
												offsetY: true,
											}"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Country of Residence"
											rounded
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-select
											:items="idtype"
											:menu-props="{
												offsetY: true,
											}"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="ID type"
											rounded
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<v-text-field
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="ID number"
											placeholder="ID number"
											rounded
											type="number"
											:rules="required8"
										/>
									</v-col>

									<v-col cols="12" md="6">
										<div class="font-weight-bold text-body-2">Gender</div>

										<v-radio-group v-model="gender" row :rules="required">
											<v-radio label="Male" value="male" />

											<v-radio label="Female" value="female" />
										</v-radio-group>
									</v-col>
								</v-row>
								<v-divider />
							</v-col>

							<v-col class="pb-0" cols="12">
								<div class="font-weight-bold text-body-1">
									Contact Information
								</div>
							</v-col>

							<v-col class="pb-0" cols="12">
								<v-row class="my-0">
									<v-col cols="12">
										<v-text-field
											v-model="payment.contactInfo.email"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Email"
											placeholder="name@jetsbooking.com"
											rounded
											type="email"
											:rules="emailRules"
										/>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="payment.contactInfo.confirmEmail"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Confirm your email address"
											placeholder="name@jetsbooking.com"
											rounded
											type="email"
											:rules="emailRules"
										/>
									</v-col>

									<v-col cols="12" md="4">
										<v-select
											v-model="payment.contactInfo.typeOfPhone"
											:items="phone"
											:menu-props="{
												offsetY: true,
											}"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Type of phone"
											rounded
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="3">
										<v-text-field
											v-model="payment.contactInfo.areaCode"
											background-color="grey lighten-4"
											class="rounded"
											filled
											hide-spin-buttons
											label="Area"
											maxlength="3"
											oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
											placeholder="301"
											rounded
											type="number"
											:rules="required"
										/>
									</v-col>

									<v-col cols="12" md="5">
										<v-text-field
											v-model="payment.contactInfo.phoneNumber"
											:counter="5"
											background-color="grey lighten-4"
											class="rounded"
											filled
											hide-spin-buttons
											label="Number"
											maxlength="11"
											oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
											placeholder="2221111"
											rounded
											type="number"
											:rules="required8"
										/>
									</v-col>

									<v-col cols="12" md="10">
										<v-checkbox v-model="tripalert" class="ma-0 pt-2">
											<template v-slot:label>
												<div>
													Receive text alerts about this trip. Message and data
													rates may apply.
												</div>
											</template>
										</v-checkbox>
									</v-col>
								</v-row>
							</v-col>

							<v-col cols="12">
								<v-divider />
							</v-col>

							<v-col class="pb-0" cols="12">
								<div class="font-weight-bold text-body-1">Payment</div>
							</v-col>

							<v-col
								class="pb-0"
								cols="12"
								v-if="this.elementsOptions.clientSecret"
							>
								<stripe-element-payment
									ref="paymentRef"
									:pk="publicKey"
									:elements-options="elementsOptions"
									:confirm-params="confirmParams"
								/>
							</v-col>
						</v-form>
					</v-row>
				</v-card>
			</v-col>

			<v-col class="trip-total" cols="12" md="4">
				<v-row>
					<v-col class="font-weight-bold text-h6"> Trip total </v-col>

					<v-col class="font-weight-bold text-h6 text-right">
						{{ getCurrency() }} ${{ getTotal() }}
					</v-col>
				</v-row>

				<v-row>
					<v-col v-if="this.getFromType !== 'Offer'" class="pt-0 text-body-1">
						<div
							class="font-weight-bold mt-1 mb-6 mb-md-auto red--text text-body-2"
						>
							* To reserve, 15% of the total will be paid:<br />
							{{ getCurrency() }} ${{ (getTotal() * 0.15).toFixed(2) }}
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0 text-body-1"> Taxes and Fees included </v-col>
				</v-row>

				<v-row>
					<v-col>
						<div class="font-weight-bold text-h6">
							Documentation required for the trip
						</div>

						<div class="text-body-1">
							Remember that in order to make your trip you must have a visa to
							enter the United States.
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="continue-button">
			<v-col class="d-flex align-center" cols="12">
				<v-btn
					@click="validateForm()"
					class="font-weight-bold mr-6"
					color="cyan"
					dark
					elevation="0"
					large
				>
					Complete booking
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';

export default {
	components: {
		StripeElementPayment,
	},
	data: () => ({
		residence: ['Argentina', 'Canada', 'United States'],
		idtype: ['Passport', 'DNI', 'SS Number'],
		tripalert: false,
		phone: ['Celular', 'Other'],
		gender: null,
		modalDateAdults: [null, null, null, null, null, null, null, null],
		modalDateChildren: [null, null, null, null, null, null, null, null],
		dateChildren: [null, null, null, null, null, null, null, null],
		dateAdults: [null, null, null, null, null, null, null, null],
		date: null,
		valid: false,
		modelConfig: {
			type: 'string',
			mask: 'YYYY-MM-DD', // Uses 'iso' if missing
		},
		month: [
			'01',
			'02',
			'03',
			'04',
			'05',
			'06',
			'07',
			'08',
			'09',
			'10',
			'11',
			'12',
		],
		year: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029'],
		emailRules: [
			(v) => !!v || 'E-mail is required',
			(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		required3: [
			(v) => !!v || 'Field is required',
			(v) => (v && v.length >= 3) || 'Field must be less than 3 characters',
		],
		required8: [
			(v) => !!v || 'Field is required',
			(v) => (v && v.length >= 8) || 'Field must be less than 8 characters',
		],
		required16: [
			(v) => !!v || 'Field is required',
			(v) => (v && v.length === 16) || 'Field must be less than 16 characters',
		],
		required: [(v) => !!v || 'Field is required'],

		payment: {
			passengers: [
				{
					name: null,
				},
			],
			contactInfo: {
				email: '',
				confirmEmail: '',
				typeOfPhone: '',
				areaCode: '',
				phoneNumber: '',
			},
			paymentIntentId: null,
		},

		publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
		elementsOptions: {
			appearance: {}, // appearance options
			clientSecret: null,
		},
		confirmParams: {
			return_url: window.location.origin + '/search/finalize', // success url
		},
	}),
	methods: {
		...mapActions([
			'apiCallToGeneratePaymentIntent',
			'paymentIntentConfirm',
			'flightFromAction',
			'flightToAction',
		]),
		async generatePaymentIntent() {
			const paymentIntentRequest = {
				total: this.getTotal(),
			};
			try {
				let paymentIntentResponse = await this.apiCallToGeneratePaymentIntent(
					paymentIntentRequest
				);
				this.elementsOptions.clientSecret =
					paymentIntentResponse.data.data.client_secret;
				this.payment.paymentIntentId =
					paymentIntentResponse.data.data.payment_intent_id;
			} catch (e) {
				console.log(e);
			}
		},
		getTotal() {
			let totalTo = 0;
			let totalFrom = parseFloat(this.getFlightFrom.price);
			if (this.getTypeSearch === 'roundTrip') {
				if (this.getFromType !== 'Pack') {
					totalTo = parseFloat(this.getFlightTo.price);
				}
			}
			return totalFrom + totalTo;
		},
		getCurrency() {
			return this.getFlightFrom.currency;
		},
		validateForm() {
			let valid = this.$refs.form.validate();
			if (valid) {
				this.paymentIntentConfirm({
					payment: this.payment,
					roundTrip: this.getTypeSearch === 'roundTrip',
					typeTrip: this.getFromType,
					depart: this.getFlightFrom,
					return: this.getFlightTo,
				});
				this.flightFromAction(null);
				this.flightToAction(null);
				this.$refs.paymentRef.submit();
			}
		},
	},
	computed: {
		...mapGetters([
			'getFlightFrom',
			'getFlightTo',
			'getAdults',
			'getChildren',
			'getFromType',
			'getToType',
			'getTypeSearch',
		]),
	},
	created() {
		if (!this.getFlightFrom) {
			this.$router.push('/flights');
		}
		for (let i = 0; i < this.getAdults; i++) {
			this.payment.passengers[i] = {
				name: null,
				lastName: null,
				dateOfBirth: null,
				country: null,
				typeDocument: null,
				documentNumber: null,
				gender: null,
			};
		}
	},
	mounted() {
		this.generatePaymentIntent();
	},
};
</script>
