<template>
    <v-bottom-navigation
        app
        background-color="cyan"
        color="white"
        dark
        grow
        height="80"
    >
        <v-btn to="/flights" class="font-weight-bold">
            <span> Flights </span>
            <v-icon x-large> mdi-magnify </v-icon>
        </v-btn>

        <v-btn to="/deals" class="font-weight-bold" v-if="isLoggedIn">
            <span> Deals </span>
            <v-icon x-large> mdi-fire </v-icon>
        </v-btn>

        <v-btn to="/account" class="font-weight-bold">
            <span> My Account </span>
            <v-icon x-large> mdi-account </v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {},
    computed: {
        ...mapGetters(["getOffers", "isLoggedIn"]),
    },
};
</script>