<template>
	<v-menu v-model="modalDate1" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-bind="attrs"
				v-on="on"
				:value="formatDateInput(range)"
				:background-color="color"
				class="rounded"
				filled
				label="Flight dates"
				prepend-inner-icon="mdi-calendar-today"
				rounded
			/>
		</template>

		<vc-date-picker
			v-model="range"
			:columns="
				$screens({
					default: 1,
					lg: 2,
				})
			"
			:is-range="isRoundTrip()"
			:min-date="new Date()"
			@input="onDateRangeChange"
			is24hr
			mode="dateTime"
			style="border: none; border-radius: 4px; width: 100%"
		/>
	</v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	data: () => ({
		modalDate1: false,
		range: {
			start: new Date(),
			end: new Date(),
		},
	}),
	props: {
		color: {
			type: String,
			default: 'white',
		},
	},
	computed: {
		...mapGetters([
			'getTypeSearch',
			'getFromDate',
			'getToDate',
			'getTypeSearch',
		]),
	},
	methods: {
		...mapActions(['fromDateAction', 'toDateAction']),
		onDateRangeChange() {
			if (this.isRoundTrip()) {
				const { start, end } = this.range;
				this.fromDateAction(this.dayjs(start).format('YYYY-MM-DD HH:mm'));
				this.toDateAction(this.dayjs(end).format('YYYY-MM-DD HH:mm'));
			} else {
				this.fromDateAction(this.dayjs(this.range).format('YYYY-MM-DD HH:mm'));
				this.toDateAction(this.dayjs(this.range).format('YYYY-MM-DD HH:mm'));
			}
		},
		formatDateInput(Dates) {
			let format = 'ddd, DD MMM, YYYY HH:mm';
			if (this.isRoundTrip()) {
				return (
					this.dayjs(Dates.start).format(format) +
					' - ' +
					this.dayjs(Dates.end).format(format)
				);
			}
			return this.dayjs(Dates).format(format);
		},
		changedSearchType() {
			this.typeSearchAction(this.toggle);
		},
		isRoundTrip() {
			return this.getTypeSearch === 'roundTrip';
		},
	},

	created() {
		if (this.getFromDate != null || this.getToDate != null) {
			if (this.getTypeSearch == 'roundTrip') {
				this.range = {
					start: this.dayjs(this.getFromDate).format(),
					end: this.dayjs(this.getToDate).format(),
				};
			} else {
				this.range = this.dayjs(this.getFromDate).format();
			}
		} else {
			let start = this.dayjs();
			let end = this.dayjs().add(15, 'day');
			this.range = {
				start: start.format(),
				end: end.format(),
			};
		}
	},
};
</script>

<style lang="scss">
.vc-pane-container > div:nth-child(3),
.vc-pane-container > div:nth-child(4) {
	display: flex;
}
.vc-time-picker.vc-bordered {
	width: 50%;
}

.v-menu__content {
	margin-top: -8px;
}
</style>
