<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="12 pa-0">
                    <v-app-bar color="transparent" flat>
                        <v-btn
                            @click="$emit('showDialog', false)"
                            class="ml-auto"
                            icon
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-col>

                <v-col cols="12" md="6">
                    <div class="font-weight-bold text-h5">
                        Create Flight Alerts
                    </div>
                </v-col>

                <v-col class="d-flex justify-md-end" cols="12" md="6">
                    <v-radio-group class="ma-0" dense row v-model="toggle">
                        <v-radio color="cyan" value="roundTrip">
                            <template v-slot:label>
                                <strong> Round Trip </strong>
                            </template>
                        </v-radio>

                        <v-radio class="ml-md-3" color="cyan" value="oneWay">
                            <template v-slot:label>
                                <strong> One Way </strong>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>

                <v-form ref="form" class="col-12 mb-9 px-0" lazy-validation>
                    <v-container class="px-3">
                        <v-row>
                            <v-col class="d-flex pb-1" cols="12">
                                <v-card
                                    color="grey lighten-4"
                                    flat
                                    rounded
                                    width="100%"
                                >
                                    <v-row>
                                        <v-col
                                            class="pb-0 pb-md-3"
                                            cols="12"
                                            md="6"
                                        >
                                            <AirportSearch
                                                :filled="true"
                                                :value="getFlightFrom"
                                                color="grey lighten-4"
                                                text="From"
                                            />
                                        </v-col>

                                        <v-divider
                                            class="d-md-none mx-3 my-0"
                                        />

                                        <v-divider
                                            class="d-none d-md-flex my-6"
                                            vertical
                                        />

                                        <v-col
                                            class="pt-0 pt-md-3"
                                            cols="12"
                                            md="6"
                                        >
                                            <AirportSearch
                                                :filled="true"
                                                :value="getFlightTo"
                                                color="grey lighten-4"
                                                text="To"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12">
                                <v-card
                                    color="grey lighten-4"
                                    flat
                                    rounded
                                    width="100%"
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <flight-dates
                                                color="grey lighten-4"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-1" cols="12" md="6">
                                <v-card
                                    color="grey lighten-4"
                                    flat
                                    rounded
                                    width="100%"
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <Passengers
                                                color="grey lighten-4"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col class="d-flex pb-0" cols="12" md="6">
                                <v-btn
                                    @click="saveForm()"
                                    block
                                    class="font-weight-bold px-12 rounded"
                                    color="cyan"
                                    dark
                                    elevation="0"
                                    height="64"
                                    x-large
                                >
                                    Create alert
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import AirportSearch from "../../components/search/AirportSearch";
import FlightDates from "../../components/search/FlightDates";
import Passengers from "../../components/search/Passengers";

export default {
    components: {
        AirportSearch,
        FlightDates,
        Passengers,
    },
    data: () => ({
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        toggle: "roundTrip",
        modalDate1: false,
        modalDate2: false,
        modalDate3: false,
        requiredCheck: [(v) => !!v || "Field is required"],
        error: false,
    }),
};
</script>

<style lang="scss">
.v-text-field__details {
    display: none !important;
}
</style>