import { flightGds, flightsApi } from '@/services/axiosURI.service';
import * as dayjs from 'dayjs';

const state = {
	searchResultSeats: [],
	searchResultPlane: [],
	searchResultPlaneOneWay: [],
	currency: '',
};

// actions
const actions = {
	async loadSearchResult({ commit, rootState }, data) {
		let paramsQuery = rootState.filters.searchParams;
		paramsQuery.sourceId = rootState.airports.searchFrom.id;
		paramsQuery.destinationId = rootState.airports.searchTo.id;
		paramsQuery.pax =
			parseInt(rootState.filters.adults) +
			parseInt(rootState.filters.children) +
			parseInt(rootState.filters.lapInfants);
		paramsQuery.oneWay = rootState.filters.typeSearch === 'oneWay';
		paramsQuery.dateDepart = rootState.filters.fromDate;
		paramsQuery.dateReturn = rootState.filters.toDate;
		paramsQuery.timeDepart = dayjs(rootState.filters.fromDate).format('HH:mm');
		paramsQuery.timeReturn = dayjs(rootState.filters.toDate).format('HH:mm');

		const {
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			timeDepart,
			scales,
			dateReturn,
			timeReturn,
			departureClass,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice,
		} = paramsQuery;

		try {
			const response = await flightsApi.getFlights(
				sourceId,
				destinationId,
				limit,
				pax,
				oneWay,
				dateDepart,
				scales,
				dateReturn,
				departureClass,
				arrivalClass,
				orderBy,
				minPrice,
				maxPrice
			);
			const { data } = response.data;
			setMaxAndMinPrice({ commit, rootState });
			commit('setSearchResultSeats', data);
		} catch (error) {
			console.error(error);
			commit('setSearchResultSeats', []);
		}

		try {
			const response = await flightGds.getFlightsGDS(
				sourceId,
				destinationId,
				limit,
				pax,
				oneWay,
				dateDepart,
				timeDepart,
				timeReturn,
				scales,
				departureClass,
				dateReturn,
				arrivalClass,
				orderBy,
				minPrice,
				maxPrice
			);
			const { data } = response.data;
			setMaxAndMinPrice({ commit, rootState });
			commit('setSearchResultPlane', data);
		} catch (error) {
			console.error(error);
			commit('setSearchResultPlane', []);
		}

		try {
			const response = await flightGds.getFlightsGDS(
				sourceId,
				destinationId,
				limit,
				pax,
				true,
				dateDepart,
				timeDepart,
				timeReturn,
				scales,
				departureClass,
				dateReturn,
				arrivalClass,
				orderBy,
				minPrice,
				maxPrice
			);
			const { data } = response.data;
			setMaxAndMinPrice({ commit, rootState });
			commit('setSearchResultPlaneOneWay', data);
		} catch (error) {
			console.error(error);
			commit('setSearchResultPlaneOneWay', []);
		}
	},

	async loadSearchResultInverted({ commit, rootState }, data) {
		let paramsQuery = rootState.filters.searchParams;
		paramsQuery.sourceId = rootState.airports.searchTo.id;
		paramsQuery.destinationId = rootState.airports.searchFrom.id;
		paramsQuery.pax =
			parseInt(rootState.filters.adults) +
			parseInt(rootState.filters.children) +
			parseInt(rootState.filters.lapInfants);
		paramsQuery.oneWay = true;
		paramsQuery.dateDepart = rootState.filters.toDate;
		paramsQuery.dateReturn = rootState.filters.toDate;
		paramsQuery.timeDepart = dayjs(rootState.filters.fromDate).format('HH:mm');
		paramsQuery.timeReturn = dayjs(rootState.filters.toDate).format('HH:mm');

		const {
			sourceId,
			destinationId,
			limit,
			pax,
			oneWay,
			dateDepart,
			timeDepart,
			scales,
			dateReturn,
			timeReturn,
			departureClass,
			arrivalClass,
			orderBy,
			minPrice,
			maxPrice,
		} = paramsQuery;

		try {
			const response = await flightGds.getFlightsGDS(
				sourceId,
				destinationId,
				limit,
				pax,
				oneWay,
				dateDepart,
				timeDepart,
				timeReturn,
				scales,
				departureClass,
				dateReturn,
				arrivalClass,
				orderBy,
				minPrice,
				maxPrice
			);
			const { data } = response.data;
			setMaxAndMinPrice({ commit, rootState });
			commit('setSearchResultPlaneOneWay', data);
		} catch (error) {
			console.error(error);
			commit('setSearchResultPlaneOneWay', []);
		}
	},
};

// mutations
const mutations = {
	setSearchResultSeats(state, searchResultSeats) {
		state.searchResultSeats = searchResultSeats;
	},

	resetRange(state) {
		state.searchResultSeats = searchResultSeats;
	},

	setSearchResultPlaneOneWay(state, searchResultPlaneOneWay) {
		state.searchResultPlaneOneWay = searchResultPlaneOneWay;
	},

	setSearchResultPlane(state, searchResultPlane) {
		state.searchResultPlane = searchResultPlane;
	},

	setCurrency(state, data) {
		state.currency = data;
	},
};

function setMaxAndMinPrice({ commit, rootState }) {
	setTimeout(() => {
		const allTrips = state.searchResultPlane.trips.concat(
			state.searchResultPlaneOneWay.trips
		);
		allTrips.forEach((element) => {
			if (element.price > rootState.filters.searchParams.maxPrice) {
				commit('setMaxPrice', element.price, { root: true });
				commit('setCurrency', element.currency);
			}

			if (element.price < rootState.filters.searchParams.minPrice) {
				commit('setMinPrice', element.price, { root: true });
			}
		});
	}, 2000);
}

// getters
const getters = {
	getSearchResultSeats: (state) => state.searchResultSeats,
	getSearchResultPlane: (state) => state.searchResultPlane,
	getSearchResultPlaneOneWay: (state) => state.searchResultPlaneOneWay,
	getCurrency: (state) => state.currency,
};
export default {
	state,
	getters,
	actions,
	mutations,
};
