<template>
	<v-app-bar app color="white" flat>
		<v-btn @click="$router.go(-1)" color="cyan" icon>
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>

		<v-spacer />

		<v-toolbar-title class="d-none font-weight-bold text-center">
			JetsBooking
		</v-toolbar-title>

		<router-link to="/flights">
			<v-img
				alt="JetsBooking Logo"
				class="shrink"
				contain
				height="40"
				src="@/assets/logo__text--black.svg"
				transition="scale-transition"
				width="178"
			/>
		</router-link>

		<v-spacer />

		<router-link to="/flights">
			<v-img
				alt="JetsBooking Logo"
				class="shrink"
				contain
				height="48"
				src="@/assets/logo__iso--primary.svg"
				transition="scale-transition"
				width="48"
			/>
		</router-link>
	</v-app-bar>
</template>

<script>
export default {
	components: {},
};
</script>
