import Alerts from "../views/account/Alerts";
import ContactUs from "../views/account/ContactUs";
import Policies from "../views/account/Policies";
import Profile from "../views/account/Profile";
import Terms from "../views/account/Terms";
import Trips from "../views/account/Trips";

export default [
  {
    path: '/account/alerts',
    component: Alerts
  },
  {
    path: '/account/contact',
    component: ContactUs
  },
  {
    path: '/policies',
    component: Policies
  },
  {
    path: '/account/profile',
    component: Profile
  },
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/account/trips',
    component: Trips
  },
]