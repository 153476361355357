import { paymentsApi } from '@/services/axiosURI.service';

// actions
const actions = {
	async paymentIntentConfirm({ commit, rootState }, data) {
		// console.log(commit)
		// console.log(rootState)
		console.log(data);
		const paymentData = {
			payment: data.payment,
		};
		if (data.typeTrip === 'Offer') {
			paymentData['depart'] = {
				vehicle_id: data.depart.vehicle.id,
				vehicle_class: data.depart.vehicle.class,
				source_id: data.depart.source.id,
				destination_id: data.depart.destination.id,
				arrivalDate: data.depart.flightDate,
				departureDate: data.depart.landingTime,
				typeTrip: data.typeTrip,
			};
		} else {
			paymentData['depart'] = {
				vehicle_id: data.depart.vehicle.id,
				vehicle_class: data.depart.vehicle.class,
				source_id: data.depart.oneWay.source.id,
				destination_id: data.depart.oneWay.destination.id,
				arrivalDate: data.depart.oneWay.arrivalDate,
				departureDate: data.depart.oneWay.departureDate,
				typeTrip: data.typeTrip,
			};
		}
		if (data.roundTrip) {
			if (data.return.oneWay) {
				paymentData['return'] = {
					vehicle_id: data.return.vehicle.id,
					vehicle_class: data.return.vehicle.class,
					source_id: data.return.oneWay.source.id,
					destination_id: data.return.oneWay.destination.id,
					arrivalDate: data.return.oneWay.arrivalDate,
					departureDate: data.return.oneWay.departureDate,
					typeTrip: data.typeTrip,
				};
			} else {
				paymentData['return'] = {
					vehicle_id: data.return.vehicle.id,
					vehicle_class: data.return.vehicle.class,
					source_id: data.return.source.id,
					destination_id: data.return.destination.id,
					arrivalDate: data.return.flightDate,
					departureDate: data.return.landingTime,
					typeTrip: data.typeTrip,
				};
			}
		}
		try {
			await paymentsApi.paymentIntentConfirm({
				data: {
					type: 'Payment',
					attributes: paymentData,
				},
			});
		} catch (e) {
			console.log(e);
		}
	},
	async apiCallToGeneratePaymentIntent({ commit, rootState }, data) {
		try {
			return await paymentsApi.createPaymentIntent({
				data: {
					type: 'PaymentIntent',
					attributes: data,
				},
			});
		} catch (e) {
			console.log(e)
			throw e;
		}
	},
};

export default {
	actions,
};
