import Login from '../views/Auth/Login';
import Password from '../views/Auth/Password';
import Register from '../views/Auth/Register';
import SignIn from '../views/Auth/SignIn';

export default [
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/recover',
		name: 'Password',
		component: Password,
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
	},
	{
		path: '/signIn',
		name: 'SignIn',
		component: SignIn,
	},
];
