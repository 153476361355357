<template>
	<v-app-bar color="white" flat height="80">
		<v-container class="d-flex px-1 py-0">
			<router-link to="/flights">
				<v-img
					alt="JetsBooking Logo"
					class="shrink"
					contain
					height="60"
					src="@/assets/logo__horizontal--primary.svg"
					transition="scale-transition"
					width="210"
				/>
			</router-link>

			<v-spacer />

			<v-toolbar-items>
				<v-btn to="/flights" class="ml-3 rounded" color="cyan" text>
					Flights
				</v-btn>

				<v-btn to="/deals" class="ml-3 rounded" color="cyan" text>
					Deals
				</v-btn>

				<v-menu left offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							class="ml-3 rounded"
							color="cyan"
							text
						>
							My Account
							<v-icon right> mdi-chevron-down </v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item
							v-for="(item, index) in menu"
							:key="index"
							:to="item.to"
							link
							color="cyan"
						>
							<v-list-item-title>
								{{ item.name }}
							</v-list-item-title>
						</v-list-item>

						<!-- LOGOUT -->
						<v-list-item @click="logout" link>
							<v-list-item-title> Logout </v-list-item-title>
						</v-list-item>
						<!-- LOGOUT -->
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-container>
	</v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	components: {},
	data: () => ({
		menus: [
			{
				name: 'Profile',
				to: '/account/profile',
			},
			{
				name: 'My Trips',
				to: '/account/trips',
			},
			{
				name: 'My Alerts',
				to: '/account/alerts',
			},
			{
				name: 'Terms and Conditions',
				to: '/terms',
			},
			{
				name: 'Contact Us',
				to: '/account/contact',
			},
		],
		dialog: true,
	}),
	computed: {
		menu() {
			return this.menus;
		},
	},
	methods: {
		...mapActions(['logout']),
	},
};
</script>
