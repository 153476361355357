<template>
	<v-container class="mb-15 pt-12 pb-9">
		<v-row class="alert-message">
			<v-col cols="12">
				<v-alert class="mb-0" color="#60ABC04D" border="left" icon="mdi-check">
					<p class="mb-0">
						Congratulations! Your reservation was made successfully.
					</p>

					<p class="mb-0">Reservation number 33206420</p>
				</v-alert>
			</v-col>
		</v-row>

		<!-- <v-row class="flight-details">
			<v-col cols="12">
				<FlightDetails />
			</v-col>
		</v-row> -->

		<v-row class="continue-button">
			<v-col cols="12">
				<v-btn
					to="/flights"
					class="font-weight-bold"
					color="cyan"
					dark
					elevation="0"
					large
				>
					Finalize
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FlightDetails from '@/components/search/FlightDetails';

export default {
	components: {
		FlightDetails,
	},
};
</script>

<style lang="scss">
.v-alert__border.v-alert__border--left {
	color: var(--v-primary-base);
	opacity: 1;
}
</style>
