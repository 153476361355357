import router from '../../router';
import { authApi } from '@/services/axiosURI.service';

const user = JSON.parse(sessionStorage.getItem('user'));

const initialState = user
	? { loggedIn: true, user, error: null }
	: { loggedIn: false, user, error: null };

// initial state
const state = () => ({
	initialState,
});

// actions
const actions = {
	async login({ commit, dispatch }, data) {
		try {
			const response = await authApi.login(data);
			if (response.data?.data) {
				commit('loginSuccess', response.data.data);
				dispatch('loadOffers', null, { root: true });
				dispatch('loadOffersCountry', null, { root: true });
			} else {
				commit('loginFailure', response.response.data);
			}
		} catch (error) {
			commit('loginFailure', error);
		}
	},

	logout({ commit }) {
		commit('logout');
		router.push('/login');
	},

	clearError({ commit }) {
		commit('clearError');
	},
};

// mutations
const mutations = {
	loginSuccess(state, user) {
		sessionStorage.setItem('user', JSON.stringify(user));
		state.initialState = { loggedIn: true, user, error: null };
		router.push('/flights');
	},
	loginFailure(state, error) {
		sessionStorage.removeItem('user');

		state.initialState = { loggedIn: false, user: null, error };
	},
	logout(state) {
		state.initialState = { loggedIn: false, user: null, error: null };
		sessionStorage.clear();
	},

	clearError(state) {
		state.initialState.error = null;
	},
};

// getters
const getters = {
	isLoggedIn: (state) => state.initialState.loggedIn,
	getError: (state) => state.initialState.error,
};

export default {
	state,
	getters,
	actions,
	mutations,
};
