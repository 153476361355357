import { airportsApi } from '@/services/axiosURI.service';

const state = {
	airports: [],
	searchFrom: JSON.parse(sessionStorage.getItem('searchFrom')),
	searchTo: JSON.parse(sessionStorage.getItem('searchTo')),
};

// actions
const actions = {
	async loadAirports({ commit }, search) {
		try {
			const response = await airportsApi.getAirports(search);
			if (Array.isArray(response.data.data)) {
				commit('setAirports', response.data.data);
			} else {
				commit('setAirports', []);
			}
		} catch (error) {
			console.error(error);
		}
	},

	async loadAirport({ commit }, id) {
		try {
			const response = await airportsApi.getOneAirport(id);
			return response.data.data;
		} catch (error) {
			console.error(error);
		}
	},

	actionFromSearch({ commit }, data) {
		commit('setSearchFrom', data);
	},

	actionToSearch({ commit }, data) {
		commit('setSearchTo', data);
	},
};

// mutations
const mutations = {
	setAirports(state, airports) {
		state.airports = airports;
	},

	setSearchFrom(state, data) {
		sessionStorage.setItem('searchFrom', JSON.stringify(data));
		state.searchFrom = data;
	},

	setSearchTo(state, data) {
		sessionStorage.setItem('searchTo', JSON.stringify(data));
		state.searchTo = data;
	},
};

// getters
const getters = {
	getAirports: (state) => {
		return state.airports;
	},

	getSearchFrom: (state) => {
		return state.searchFrom;
	},

	getSearchTo: (state) => {
		return state.searchTo;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
