<template>
	<v-container class="mb-15 pt-12 pb-9">
		<v-row class="section-title">
			<v-col cols="12">
				<div class="font-weight-bold text-h6">Review Flight Details</div>
			</v-col>
		</v-row>

		<v-row class="flight-details">
			<v-col cols="12">
				<FlightDetails
					:flight="getFlightFrom"
					:from="getFlightFrom"
					:to="getFlightTo"
				/>
			</v-col>
		</v-row>

		<v-row class="flight-details" v-if="isRoundTrip()">
			<v-col cols="12">
				<FlightDetails
					:flight="getFlightTo"
					:from="getFlightTo"
					:to="getFlightFrom"
				/>
			</v-col>
		</v-row>

		<v-row class="continue-button">
			<v-col class="d-flex align-center" cols="12">
				<v-btn
					v-bind="attrs"
					v-on="on"
					to="/search/continue"
					class="font-weight-bold mr-6"
					color="cyan"
					dark
					elevation="0"
					large
				>
					Continue
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FlightDetails from '@/components/search/FlightDetails';

export default {
	components: {
		FlightDetails,
	},
	methods: {
		isRoundTrip() {
			return this.getTypeSearch === 'roundTrip';
		},
	},
	computed: {
		...mapGetters([
			'getFlightFrom',
			'getFlightTo',
			'getFlightFrom',
			'getFlightTo',
			'getFromDate',
		]),
	},
};
</script>
