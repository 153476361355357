<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="12 pa-0">
                    <v-app-bar color="transparent" flat>
                        <v-btn
                            @click="$emit('showDialog', false)"
                            class="ml-auto"
                            icon
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-col>

                <v-col class="d-flex pt-0" cols="12">
                    <div class="font-weight-bold text-h5">
                        Edit account details
                    </div>
                </v-col>

                <v-container class="px-3">
                    <v-row>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            class="col-12 pa-0"
                        >
                            <v-col class="d-flex pb-1" cols="12">
                                <v-text-field
                                    v-model="firstName"
                                    background-color="grey lighten-4"
                                    class="rounded"
                                    filled
                                    label="Name"
                                    placeholder="Jane"
                                    rounded
                                    :rules="required3"
                                />
                            </v-col>

                            <v-col class="d-flex pt-0 pb-1" cols="12">
                                <v-text-field
                                    v-model="lastName"
                                    background-color="grey lighten-4"
                                    class="rounded"
                                    filled
                                    label="Last Name"
                                    placeholder="Doe"
                                    rounded
                                    :rules="required3"
                                />
                            </v-col>

                            <v-col class="d-flex pt-0 pb-1" cols="12">
                                <v-text-field
                                    v-model="email"
                                    background-color="grey lighten-4"
                                    class="rounded"
                                    filled
                                    label="Email"
                                    placeholder="name@jetsbooking.com"
                                    rounded
                                    type="email"
                                    disabled
                                />
                            </v-col>

                            <v-col class="d-flex pt-0 pb-1" cols="12">
                                <v-text-field
                                    v-model="password"
                                    background-color="grey lighten-4"
                                    class="rounded"
                                    clearable
                                    filled
                                    label="Password"
                                    placeholder="**********"
                                    rounded
                                    type="password"
                                    :rules="requiredPassword"
                                />
                            </v-col>

                            <v-col class="d-flex pt-0 pb-1" cols="12">
                                <v-text-field
                                    v-model="repeatPassword"
                                    background-color="grey lighten-4"
                                    class="rounded"
                                    clearable
                                    filled
                                    label="Confirm Password"
                                    placeholder="**********"
                                    rounded
                                    type="password"
                                    :rules="requiredPassword"
                                />
                            </v-col>

                            <v-col class="d-flex justify-center" cols="12">
                                <v-btn
                                    :disabled="!valid"
                                    @click="updateData()"
                                    block
                                    class="font-weight-bold mb-9 rounded"
                                    color="cyan"
                                    dark
                                    elevation="0"
                                    x-large
                                >
                                    Save Changes
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-row>
                </v-container>
            </v-row>
        </v-container>
    </v-card>
</template>


<script>
import Tab from "@/components/Tab";
import EditProfileDialog from "@/components/myAccount/EditProfileDialog";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Tab,
        EditProfileDialog,
    },
    data: () => ({
        valid: true,
        dialog: false,
        firstName: "",
        lastName: "",
        password: "",
        repeatPassword: "",
        required3: [
            (v) => !!v || "Field is required",
            (v) =>
                (v && v.length >= 3) || "Field must be less than 3 characters",
        ],
        requiredPassword: [
            (v) => !!v || "Field is required",
            (v) =>
                (v && v.length >= 5) || "Field must be less than 5 characters",
        ],
    }),
    methods: {
        ...mapActions(["updateUserAction"]),
        checkPassword() {
            return this.password === this.repeatPassword;
        },
        async updateData() {
            if (this.$refs.form.validate()) {
                if (this.checkPassword()) {
                    try {
                        await this.updateUserAction({
                            firstName: this.firstName,
                            lastName: this.lastName,
                            password: this.password,
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    created() {
        this.firstName = this.getUser.firstName;
        this.lastName = this.getUser.lastName;
        this.email = this.getUser.email;
    },
};
</script>