<template>
    <v-card class="px-3" color="white" flat rounded="0">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            to="/deals"
                            :elevation="hover ? 3 : 0"
                            class="
                                overflow-hidden
                                rounded-lg
                                text-decoration-none
                                transition-swing
                            "
                            dark
                            flat
                            width="100%"
                        >
                            <v-img
                                class="align-center justify-center text-center"
                                gradient="to bottom, rgba(0,0,0,.68), rgba(0,0,0,.68)"
                                height="400"
                                src="@/assets/deals__plane--inside.jpg"
                            >
                                <v-card-title
                                    class="
                                        font-weight-bold
                                        justify-center
                                        pb-1
                                        text-h5 text-md-h4
                                    "
                                >
                                    FLIGHTS TO MENDOZA
                                </v-card-title>

                                <v-card-text>
                                    <p
                                        class="
                                            font-weight-bold
                                            mb-9
                                            text-h6 text-md-h5
                                            white--text
                                        "
                                    >
                                        Boca - River
                                    </p>

                                    <v-btn
                                        to="/deals"
                                        color="cyan"
                                        elevation="0"
                                    >
                                        View more
                                    </v-btn>
                                </v-card-text>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    components: {},
    data: () => ({}),
    methods: {
        ...mapActions(["loadOffersCountry"]),
    },
    computed: {
        ...mapGetters(["getOffersCountry", "isLoggedIn"]),
    },
    async created() {
        if (this.isLoggedIn) {
            await this.loadOffersCountry();
        }
    },
};
</script>