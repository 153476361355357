<template>
	<div class="pb-15">
		<Tab title="My Trips" />

		<v-container class="py-12">
			<v-row v-model="previousTrips">
				<v-col cols="12">
					<div class="font-weight-bold text-h6">Previous trips</div>
				</v-col>

				<v-col v-for="(item, i) in items1" :key="i" cols="12">
					<v-hover v-slot="{ hover }">
						<v-card
							@click=""
							:elevation="hover ? 6 : 0"
							class="overflow-hidden pa-6 rounded-lg transition-swing"
							width="100%"
						>
							<v-row>
								<v-col class="d-none d-md-block" cols="12" md="4">
									<v-card flat>
										<v-img
											:src="item.image"
											class="align-center justify-center text-center"
											height="160px"
										/>
									</v-card>
								</v-col>

								<v-col
									class="d-flex flex-column flex-wrap pb-0 pb-md-3"
									cols="12"
									md="6"
								>
									<div class="font-weight-bold cyan--text">
										Confirmed reservation
									</div>

									<div v-text="item.reservation" class="text-body-2" />

									<div
										class="align-start d-flex flex-nowrap font-weight-bold my-6 mb-md-0"
									>
										<v-img
											:src="item.logo"
											class="mt-1 mr-2"
											contain
											height="25px"
											max-width="25px"
										/>

										<span v-text="item.plane" />
									</div>

									<div class="trip__grid d-md-none mb-6 text-body-1">
										<div
											v-text="item.from"
											class="trip__grid--where-from font-weight-bold"
										/>

										<div class="trip__grid--time-from">06:23</div>

										<div
											class="trip__grid--type font-weight-bold green--text text--lighten-1 text-center"
										>
											Direct
										</div>

										<div
											v-text="item.to"
											class="trip__grid--where-to font-weight-bold"
										/>

										<div class="trip__grid--time-to">09:40</div>
									</div>

									<div class="text-body-1">
										Duration: 3 hours 17m

										<span class="d-none d-md-inline"> - </span>

										<span class="d-none d-md-inline green--text">
											Direct flight
										</span>
									</div>
								</v-col>

								<v-col
									class="d-flex flex-md-column flex-wrap justify-space-between pt-0 pt-md-3 text-md-right"
									cols="12"
									md="2"
								>
									<div class="font-weight-bold cyan--text">Business Jet</div>

									<div v-text="item.fromto" class="d-none d-md-block" />

									<div class="mt-auto cyan--text">See detail</div>
								</v-col>
							</v-row>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>

			<v-row v-model="currentTrips">
				<v-col cols="12">
					<div class="font-weight-bold text-h6">Current trips</div>
				</v-col>

				<v-col v-for="(item, i) in items2" :key="i" cols="12">
					<v-hover v-slot="{ hover }">
						<v-card
							@click=""
							:elevation="hover ? 6 : 0"
							class="overflow-hidden pa-6 rounded-lg transition-swing"
							width="100%"
						>
							<v-row>
								<v-col class="d-none d-md-block" cols="12" md="4">
									<v-card flat>
										<v-img
											:src="item.image"
											class="align-center justify-center text-center"
											height="160px"
										/>
									</v-card>
								</v-col>

								<v-col
									class="d-flex flex-column flex-wrap pb-0 pb-md-3"
									cols="12"
									md="6"
								>
									<div class="font-weight-bold cyan--text">
										Confirmed reservation
									</div>

									<div v-text="item.reservation" class="text-body-2" />

									<div
										class="align-start d-flex flex-nowrap font-weight-bold my-6 mb-md-0"
									>
										<v-img
											:src="item.logo"
											class="mt-1 mr-2"
											contain
											height="25px"
											max-width="25px"
										/>

										<span v-text="item.plane" />
									</div>

									<div class="trip__grid d-md-none mb-6 text-body-1">
										<div
											v-text="item.from"
											class="trip__grid--where-from font-weight-bold"
										/>

										<div class="trip__grid--time-from">06:23</div>

										<div
											class="trip__grid--type font-weight-bold green--text text--lighten-1 text-center"
										>
											Direct
										</div>

										<div
											v-text="item.to"
											class="trip__grid--where-to font-weight-bold"
										/>

										<div class="trip__grid--time-to">09:40</div>
									</div>

									<div class="text-body-1">
										Duration: 3 hours 17m

										<span class="d-none d-md-inline"> - </span>

										<span class="d-none d-md-inline green--text">
											Direct flight
										</span>
									</div>
								</v-col>

								<v-col
									class="d-flex flex-md-column flex-wrap justify-space-between pt-0 pt-md-3 text-md-right"
									cols="12"
									md="2"
								>
									<div class="font-weight-bold cyan--text">Business Jet</div>

									<div v-text="item.fromto" class="d-none d-md-block" />

									<div class="mt-auto cyan--text">See detail</div>
								</v-col>
							</v-row>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import Tab from '@/components/Tab';

export default {
	components: {
		Tab,
	},
	data: () => ({
		previousTrips: 1,
		items1: [
			{
				image: require('@/assets/deals__plane--outside.jpg'),
				reservation: 'Reservation Nº 33206420',
				logo: require('@/assets/trips__logo--cessna.svg'),
				plane: 'CESSNA Citation Sovereign',
				from: 'MIA',
				to: 'JFK',
				fromto: 'MIA - JFK',
			},
		],
		currentTrips: 3,
		items2: [
			{
				image: require('@/assets/deals__plane--outside.jpg'),
				reservation: 'Reservation Nº 12412',
				logo: require('@/assets/trips__logo--ventus.svg'),
				plane: 'The Way of CESSNA',
				from: 'MIA',
				to: 'JFK',
				fromto: 'MIA - JFK',
			},
			{
				image: require('@/assets/deals__nature--mendoza.jpg'),
				reservation: 'Reservation Nº 12412',
				logo: require('@/assets/trips__logo--cessna.svg'),
				plane: 'CESSNA Only Sovereign',
				from: 'WAS',
				to: 'PAR',
				fromto: 'WAS - PAR',
			},
			{
				image: require('@/assets/deals__plane--inside.jpg'),
				reservation: 'Reservation Nº 467457',
				logo: require('@/assets/trips__logo--american-airlines.svg'),
				plane: 'CESSNA Premium Jet',
				from: 'TEX',
				to: 'LAX',
				fromto: 'TEX - LAX',
			},
		],
	}),
};
</script>

<style lang="scss" scoped>
.trip__grid {
	align-items: center;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas: 'where-from trip-type where-to' 'time-from trip-type time-to';
	justify-items: center;

	&--where-from {
		grid-area: where-from;
	}

	&--where-to {
		grid-area: where-to;
	}

	&--type {
		border-bottom: 1px solid;
		grid-area: trip-type;
		width: 100%;
	}

	&--where-to {
		grid-area: where-to;
	}

	&--time-to {
		grid-area: time-to;
	}
}
</style>
