<template>
	<div class="pb-15">
		<Tab title="Search Results" />

		<v-container class="py-12">
			<v-card color="transparent" flat width="100%" />
		</v-container>
	</div>
</template>

<script>
import Tab from '@/components/Tab';

export default {
	components: {
		Tab,
	},
};
</script>
