import Account from '../views/Account';
import Deals from '../views/Deals';
import Flights from '../views/Flights';
import Home from '../views/Home';
import Search from '../views/Search';

export default [
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
	},
	{
		path: '/deals',
		name: 'Deals',
		component: Deals,
	},
	{
		path: '/deals/:country?',
		name: 'Deals by Country',
		component: Deals,
		props: true,
	},
	{
		path: '/flights',
		name: 'Flights',
		component: Flights,
	},
	{
		path: '/search',
		name: 'Search',
		component: Search,
	},
];
