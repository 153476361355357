import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import filters from './modules/filters';
import search from './modules/search';
import offers from './modules/offers';
import airports from './modules/airports';
import user from './modules/user';
import payment from './modules/payment';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		airports,
		auth,
		filters,
		offers,
		user,
		search,
		payment,
	},
});
