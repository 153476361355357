<template>
    <div>
        <Search />

        <!-- <Deals /> -->

        <!-- <DealsBig /> -->

        <FollowUs />

        <Partners />

        <Numbers />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Search from "../components/Flights/Search";
import Deals from "../components/Flights/Deals";
import DealsBig from "../components/Flights/DealsBig";
import FollowUs from "../components/Flights/FollowUs";
import Partners from "../components/Flights/Partners";
import Numbers from "../components/Flights/Numbers";

export default {
    components: {
        Search,
        Deals,
        DealsBig,
        FollowUs,
        Partners,
        Numbers,
    },
    methods: {
        ...mapActions(["loadOffers", "loadOffersCountry","flightFromAction","flightToAction"]),
    },
    computed: {
        ...mapGetters(["getOffers", "getOffersCountry", "isLoggedIn"]),
    },
    async created() {
        if (this.isLoggedIn) {
            await this.loadOffers();
            await this.loadOffersCountry();
            this.flightFromAction(null);
            this.flightToAction(null);
        }
    },
};
</script>
