<template>
	<div class="pb-15">
		<Tab title="My Account" />

		<v-container class="px-3 py-12">
			<v-row class="flex-column">
				<v-col class="px-8">
					<div class="font-weight-bold text-h4">Peter Rockwell</div>

					<div class="mb-9 text-h6">peterrockwell@gmail.com</div>
				</v-col>

				<v-divider />

				<v-col class="pa-0">
					<v-list class="pa-0" color="transparent">
						<v-list-item-group>
							<v-list-item to="/account/profile" class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="cyan" dark elevation="0" fab small>
										<v-icon> mdi-account </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold cyan--text text-h6"
									>
										Profile
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item to="/account/trips" class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="cyan" dark elevation="0" fab small>
										<v-icon> mdi-airplane </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold cyan--text text-h6"
									>
										My Trips
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item to="/account/alerts" class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="cyan" dark elevation="0" fab small>
										<v-icon> mdi-bell </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold cyan--text text-h6"
									>
										My Alerts
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>

				<v-divider />

				<v-col class="pa-0">
					<v-list class="pa-0" color="transparent">
						<v-list-item-group>
							<v-list-item to="/account/contact" class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="grey" dark elevation="0" fab small>
										<v-icon> mdi-email </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold grey--text text--darken-2 text-h6"
									>
										Contact Us
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item to="/terms" class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="grey" dark elevation="0" fab small>
										<v-icon> mdi-information-variant </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold grey--text text--darken-2 text-h6"
									>
										Terms and Conditions
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>

				<v-divider />

				<v-col class="pa-0">
					<v-list class="pa-0" color="transparent">
						<v-list-item-group>
							<v-list-item @click="logout" link class="px-8">
								<v-list-item-icon class="mr-4">
									<v-btn color="grey lighten-2" dark elevation="0" fab small>
										<v-icon> mdi-logout-variant </v-icon>
									</v-btn>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title
										class="font-weight-bold grey--text text-h6"
									>
										Logout
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import Tab from '@/components/Tab';

export default {
	components: {
		Tab,
	},
	methods: {
		...mapActions(['logout']),
	},
};
</script>
