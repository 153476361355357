<template>
    <v-container class="mb-15 pt-12 pb-9">
        <v-row class="section-title">
            <v-col cols="12">
                <div class="font-weight-bold text-h6">
                    Review Flight Details
                </div>
            </v-col>
        </v-row>

        <v-row class="flight-details">
            <v-col cols="12">
                <FlightDetailsGDS
                    :flight="getFlightFrom"
                    :flightData="getFlightFrom.oneWay"
                />
            </v-col>

            <v-col cols="12">
                <FlightFeatures :flight="getFlightFrom" />
            </v-col>
        </v-row>

        <v-row class="flight-details">
            <v-col cols="12">
                <FlightDetailsGDS
                    :flight="getFlightTo"
                    :flightData="getFlightTo.oneWay"
                    :showPrice="true"
                />
            </v-col>

            <v-col cols="12">
                <FlightFeatures :flight="getFlightTo" />
            </v-col>
        </v-row>

        <v-row class="continue-button">
            <v-col class="d-flex align-center" cols="12">
                <v-btn
                    to="/search/continue"
                    class="font-weight-bold mr-6"
                    color="cyan"
                    dark
                    elevation="0"
                    large
                >
                    Continue
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FlightDetailsGDS from "@/components/search/FlightDetailsGDS";
import FlightFeatures from "@/components/search/FlightFeatures";

export default {
    components: {
        FlightDetailsGDS,
        FlightFeatures,
    },
    computed: {
        ...mapGetters([
            "getFlightFrom",
            "getFlightTo",
            "getFlightFrom",
            "getFlightTo",
            "getFromDate",
        ]),
    },
};
</script>
