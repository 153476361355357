<template>
	<v-container class="mb-15 pt-12 pb-9">
		<v-col cols="12">
			<div class="text-h6 font-weight-bold">Your flight details</div>
		</v-col>
		<v-expansion-panels>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<v-row>
						<v-col cols="12">
							<FlightDetails
								v-if="getFromType == 'Offer'"
								:flight="getFlightFrom"
							/>

							<FlightDetailsGDS
								v-else
								:flight="getFlightFrom"
								:flightData="getFlightFrom.oneWay"
							/>
						</v-col>
					</v-row>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-col cols="12">
						<FlightFeatures :flight="getFlightFrom" />
					</v-col>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<v-divider class="my-16" v-if="isRoundTrip()" />
		<v-expansion-panels v-if="isRoundTrip()">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<v-row>
						<v-col cols="12">
							<FlightDetails
								v-if="getToType == 'Offer'"
								:flight="getFlightTo"
								type="Return"
							/>
							<FlightDetailsGDS
								v-if="getToType == 'GDS'"
								:flight="getFlightTo"
								:flightData="getFlightTo.oneWay"
								:showPrice="getFromType != 'Pack'"
								type="Return"
							/>
							<FlightDetailsGDS
								v-if="getToType == 'Pack'"
								:flight="getFlightTo"
								:flightData="getFlightTo.roundTrip"
								:showPrice="getFromType != 'Pack'"
								type="Return"
							/>
						</v-col>
					</v-row>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-col cols="12">
						<FlightFeatures :flight="getFlightTo" />
					</v-col>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-divider class="my-16" />

		<v-row class="legal-notices">
			<v-col class="d-flex flex-wrap" cols="12">
				<div class="d-flex align-center mr-9 text-body-1">
					<v-icon small> mdi-chevron-right </v-icon>

					<a href="/terms" class="blue--text text--accent-4" target="_blank">
						Cancellation policy
					</a>
				</div>

				<div class="d-flex align-center mr-9 text-body-1">
					<v-icon small> mdi-chevron-right </v-icon>

					<a href="/terms" class="blue--text text--accent-4" target="_blank">
						Terms and Conditions
					</a>
				</div>

				<div class="d-flex align-center text-body-1">
					<v-icon small> mdi-chevron-right </v-icon>

					<a href="/terms" class="blue--text text--accent-4" target="_blank">
						Boarding Policy
					</a>
				</div>
			</v-col>
		</v-row>

		<v-row class="continue-button">
			<v-col class="align-center d-flex flex-wrap" cols="12">
				<v-btn
					to="/search/continue"
					class="font-weight-bold mr-6 order-2 order-md-1"
					color="cyan"
					dark
					elevation="0"
					large
				>
					Checkout
				</v-btn>

				<v-checkbox
					v-model="checkbox"
					class="ma-0 order-1 order-md-2 pb-6 pt-md-2 pb-md-0"
				>
					<template v-slot:label>
						<div>
							Selecting this flight I agree to all
							<a target="_blank" href="/terms" @click.stop>
								terms and conditions
							</a>
						</div>
					</template>
				</v-checkbox>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FlightDetails from '@/components/search/FlightDetails';
import FlightFeatures from '@/components/search/FlightFeatures';
import FlightDetailsGDS from '@/components/search/FlightDetailsGDS';

export default {
	components: {
		FlightDetails,
		FlightFeatures,
		FlightDetailsGDS,
	},
	data: () => ({
		checkbox: false,
		items: [
			{ text: 'Toilette', icon: 'mdi-faucet' },
			{ text: 'Wi-Fi', icon: 'mdi-wifi' },
			{ text: 'Satellite Phone', icon: 'mdi-satellite-uplink' },
			{
				text: 'No check-ins required',
				icon: 'mdi-checkbox-marked-outline',
			},
			{ text: 'Coffee pot', icon: 'mdi-coffee-maker' },
			{ text: 'Pets allowed', icon: 'mdi-paw' },
			{ text: 'Headsets', icon: 'mdi-headphones' },
			{ text: 'Catering VIP', icon: 'mdi-silverware-fork-knife' },
		],
	}),
	methods: {
		isRoundTrip() {
			return this.getTypeSearch === 'roundTrip';
		},
	},
	computed: {
		...mapGetters([
			'getFlightFrom',
			'getFlightTo',
			'getFlightFrom',
			'getFlightTo',
			'getFromDate',
			'getTypeSearch',
			'getFromType',
			'getToType',
		]),
	},
	created() {
		if (!this.getFlightFrom || !this.getFlightTo) {
			this.$router.push('/flights');
		}
	},
};
</script>
