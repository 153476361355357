import Vue from 'vue';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var advancedFormat = require('dayjs/plugin/advancedFormat')
var duration = require('dayjs/plugin/duration')
// var customParseFormat = require('dayjs/plugin/customParseFormat')
// var relativeTime = require('dayjs/plugin/relativeTime')



dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(advancedFormat)



Object.defineProperties(Vue.prototype, {
    dayjs: {
        get() {
            return dayjs
        }
    }
});