import Vue from 'vue';
import VueRouter from 'vue-router';
import loginRoutes from './loginRoutes';
import accountRoutes from './accountRoutes';
import searchRoutes from './searchRoutes';
import viewRoutes from './viewRoutes';
import PageNotFound404 from '../views/PageNotFound404';

Vue.use(VueRouter);

const routes = [
	...loginRoutes,
	...viewRoutes,
	...searchRoutes,
	...accountRoutes,
	{ path: '*', component: PageNotFound404 },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ['/terms', '/recover', '/login', '/signIn', '/register','/home','/'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = sessionStorage.getItem('user');

	if (to.name === '/home' || to.path === '/') {
		return next('/home');
	}

	//Usuario no logueado
	if (authRequired && !loggedIn) {
		return next('/login');
	}

	//Usuario logueado que quiere acceder al login o SignIn
	if (
		(to.name === 'Login' && loggedIn) ||
		(to.name === 'SignIn' && loggedIn)
	) {
		return next('/flights');
	}
	next();
});

export default router;
