<template>
	<v-card
		class="d-flex align-center"
		color="grey lighten-4"
		flat
		height="100%"
		rounded="0"
	>
		<v-container>
			<v-card
				class="mx-auto px-0 px-md-16 pb-16 rounded-lg"
				max-width="700"
				width="100%"
			>
				<v-container>
					<v-row>
						<v-col class="ml-md-n12 px-0 py-6" cols="12">
							<v-btn @click="$router.go(-1)" class="mr-auto" color="cyan" icon>
								<v-icon>mdi-arrow-left</v-icon>
							</v-btn>
						</v-col>

						<v-col class="d-flex justify-center pt-0" cols="12">
							<v-img
								class="mb-6"
								max-height="160"
								max-width="270"
								src="@/assets/logo__slogan--primary.svg"
							/>
						</v-col>

						<v-form class="col-12 pa-0">
							<v-alert
								@input="handleClearError()"
								v-if="getError"
								type="error"
								dismissible
							>
								Check your credentials and reintent
							</v-alert>
							<v-container class="px-3">
								<v-row>
									<v-col class="d-flex pb-1" cols="12">
										<v-text-field
											v-model="username"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Email"
											placeholder="name@jetsbooking.com"
											rounded
										/>
									</v-col>

									<v-col class="d-flex pt-0 pb-1" cols="12">
										<v-text-field
											v-model="password"
											background-color="grey lighten-4"
											class="rounded"
											filled
											label="Password"
											placeholder="**********"
											type="password"
											rounded
										/>
									</v-col>

									<v-col class="d-flex justify-center" cols="12">
										<v-btn
											@click="signIn"
											block
											class="font-weight-bold rounded"
											color="cyan"
											dark
											elevation="0"
											x-large
										>
											Sign in
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-form>

						<v-col cols="12">
							<v-divider />
						</v-col>

						<v-col
							class="d-flex flex-wrap justify-center text-body-1"
							cols="12"
						>
							<span
								@click="showScreen('/recover')"
								class="col-sm-auto mb-3 pa-0 text-body-1 text-center"
								role="button"
							>
								Forgot your password?
							</span>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-container>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	data: () => ({
		username: 'test@jetsbooking.com',
		password: '1234',
	}),
	computed: {
		...mapGetters(['getError']),
	},
	methods: {
		...mapActions(['login', 'actionScreen', 'clearError']),
		signIn() {
			try {
				this.login({
					username: this.username,
					password: this.password,
				});
			} catch (error) {
				console.log({ error });
			}
		},
		showScreen(screen) {
			this.$router.push(screen);
		},
		handleClearError() {
			this.clearError();
		},
	},
};
</script>
